import config from 'config';

const assetPrefix = config.has('assetPrefix') ? config.get('assetPrefix') : '';
const EMAIL_MAX_LENGTH = 40;

export const teaserI18n = Object.freeze({
  ratingDescription: 'rating',
});

export const commonI18n = Object.freeze({
  correctEmailTypoText: 'Did you mean: ',
});

export const alertsI18n = Object.freeze({
  mobileAlertsText: 'Follow',
  desktopAlertsText: 'Follow',
  mobileAlertsTextFollow: 'Following',
  mobileAlertsTextUnfollow: 'Follow',
  alertsTextA11y: 'Get email notification for articles from ',
  podcastAllEpisodesText: 'All Episodes',
  podcastAllEpisodesUrl: 'https://www.haaretz.com/digital/podcast',
  campainName: 'send',
});

export const userStoryTopicI18n = Object.freeze({
  name: 'Name',
  namePlaceholder: 'Enter your name',
  location: 'Location',
  locationPlaceholder: 'Where you from ?',
  content: 'Your content',
  contentPlaceHolder: 'Tel us your story here',
  send: 'Send',
  successTitle: 'Your story sent succesafuly',
  successSubtitle: 'Thanks for sharing!',
  successResetPre: 'To share another story',
  successReset: 'Click here',
});

export const adsI18n = Object.freeze({
  adTitle: 'Advertisment',
});

export const seriesArticleI18n = Object.freeze({
  loadButton: Object.freeze({
    open: count => 'Load More',
    close: 'Close',
    ariaText: x => `${x} articles were added`,
  }),
  titlePrefix: 'Article series: ',
});

export const zoominText = 'zoom in';
export const zoomoutText = 'zoom out';

export const galleryI18n = Object.freeze({
  open: 'Open gallery view',
  captionPrefix: (current, total) => `${current} of ${total} | `,
  mobileList: total => `${total} Pictures`,
});

export const textInputI18n = Object.freeze({
  requiredLong: 'Required',
  requiredShort: '*',
});

export const tagsElementI18n = Object.freeze({
  prefix: 'Click the alert icon to follow topics:',
  bioTitle: 'Description',
});
export const topNewsI18n = Object.freeze({
  title: 'In The News:',
  externalId: 'com.hdc.content.TopNewsElement',
  useInstantSearchHits: false,
});

export const breadcrumbsI18n = Object.freeze({
  ariaLabel: 'Breadcrumb',
});

export const clickTrackerI18n = Object.freeze({
  promotedContentLabel: 'Promoted content',
});


export const navigationMenuI18n = Object.freeze({
  buttonText: '',
  a11yTexts: Object.freeze({
    subMenu: (title, isOpen) => `${isOpen ? 'close' : 'open'} sub-menu ${title}`,
  }),
});

export const mobileNavigationMenuI18n = Object.freeze({
  buttonText: 'Navigate',
  close: 'Close menu',
  open: 'Open menu',
  home: 'Home',
  sectionsTitle: 'All Sections',
  siteName: 'Haaretz.com',
  more: 'More',
  back: 'Back',
  login: {
    text: 'Log in',
    campaignDetailsText: 'Login',
    campaignDetailsIcon: 'Icon',
    utmContent: 'login',
  },
  subscribe: {
    text: 'Subscribe',
    campaignDetails: 'Subscribe',
  },
  promotedLink: {
    href: 'https://www.haaretz.com/most-read-news-from-israel-and-the-middle-east',
    text: 'Top 10',
  },
});

export const mobileQuickRegistrationI18n = Object.freeze({
  signedUpText: 'Thank you for signing up',
  disclaimer: null,
});

export const headerSearchI18n = Object.freeze({
  buttonText: 'Search',
  placeHolder: 'Type To Search',
  a11yTexts: Object.freeze({
    close: 'click to close search',
    execSearch: 'click to perform a search',
  }),
  queryUrl: query => `https://www.haaretz.com/misc/search-results?text=${query}&searchType=textSearch`,
  searchHistoryLabel: 'Latest Search',
  loadMore: 'Load more',
  noResultsText: 'No results found',
  errorText: 'Network error',
  foundResultsText: 'We found {__COUNT__} articles for search {__QUERY__}',
  resultsListTitle: 'Articles',
  resultsPageTitle: 'Haaretz - Search results',
  resultsForPageTitle: 'Haaretz - Search results for:',
});

export const recipesSearchI18n = Object.freeze({
  placeHolder: 'Search recipes here',
});

export const hotTopicsI18n = Object.freeze({
  label: 'Hot topics',
});

export const homepageLogoLink = Object.freeze({
  a11yText: 'Haaretz - back to home page',
});

export const mobileSearchI18n = Object.freeze({
  buttonText: 'Search',
  placeHolder: 'Type To Search',
  queryUrl: query => `https://www.haaretz.com/misc/mobile-search-results?text=${query}&searchType=textSearch`,
  searchHistoryLabel: 'Recent searches',
});

export const osakaI18n = Object.freeze({
  backToHome: 'Back to Home page',
  backToSection: 'Back to Section',
  nextArticle: 'Next Article',
  promotedContent: 'Promoted Content',
});

export const commentI18n = Object.freeze({
  tags: Object.freeze({
    editorsPick: 'Editors pick',
    usersPick: 'Users pick',
  }),
  buttons: Object.freeze({
    readMoreBtnTxt: 'Read more',
    replyBtnTxt: 'Reply',
    reportAbuseBtnTxt: 'Report',
    reportReceivedTxt: 'Report received',
    reportErrorTxt: 'Try again',
  }),
  ariaTexts: Object.freeze({
    subCommentTxt: 'Reply to comment',
  }),
  tooltip: Object.freeze({
    ratedUserText: rating => `דירוג: ${rating}. הדירוג (בסולם של 1-100) מתבסס על איכות התכנים, מספר הלייקים והתגובות שקיבלו. מנויים שמעוניינים להיכנס לדירוג צריכים לבחור כינוי קבוע`,
    verifiedUserText: 'אומת ע״י המערכת',
  }),
  commercial: 'commercial',
});

export const commentFormI18n = Object.freeze({
  nickName: Object.freeze({
    or: ' or ',
  }),
  buttons: Object.freeze({
    sendBtnTxt: 'Send',
    cancelBtnTxt: 'Cancel',
    toggleUserBtnText: identified => (identified ? 'Add anonymous comment' : 'Add identified comment'),
    haaretzSheliUrl: 'https://www.haaretz.co.il/personal-area/haaretz-sheli?shownickname',
    addUserNickName: 'Get a nickname',
    nonPayingNickNamePromotionBtn: 'I want a nickname',
    promotionUrl: buttonText => `https://promotion.haaretz.co.il/offers?htm_source=site&htm_medium=Talkbacks&htm_campaign=Nickname_Promotion&htm_content=${buttonText}`,
    nonPayingNickNameNoThanks: 'No thanks',
    nickNameChangeNowTxt: 'Change now',
    cancelNickName: 'Cancel',
    retrySetNicknameTxt: 'Try again',
    confirmSetNickname: 'Update',
    cancelSetNickname: 'Cancel',
  }),
  labels: Object.freeze({
    nameLabelTxt: 'Name',
    commentLabelTxt: 'Comment',
  }),
  notes: Object.freeze({
    nameNoteTxt: 'Enter the commenter display name',
    commentNoteTxt: 'By adding a comment, I agree to this site’s',
    termsOfUse: Object.freeze({
      text: 'Terms of use',
      link: 'https://www.haaretz.com/misc/terms-and-conditions',
    }),
  }),
  errorNotes: Object.freeze({
    nameErrorNoteTxt: 'Name is required',
    commentErrorNoteTxt: 'Comment is required',
    commentErrorTooLongNoteTxt: 'Comment max length is 1,000 chars',
  }),
  texts: Object.freeze({
    nonPayingNickNameAlertText:
      'Digital subscribers can choose a permanent nickname, get stars, and enjoy quick responses',
  }),
});
export const commentSentI18n = Object.freeze({
  buttons: Object.freeze({
    getNotificationsBtnTxt: 'Keep me posted',
    dontGetNotificationsBtnTxt: 'No thanks',
    closeBtnText: 'Close',
    goBackBtnText: 'Back',
  }),
  labels: Object.freeze({
    emailLabelTxt: 'Email',
  }),
  notes: Object.freeze({
    emailNoteTxt: 'Please enter a valid email address',
  }),
  errorNotes: Object.freeze({
    emailErrorNoteTxt: 'Please enter a valid email address',
  }),
  texts: Object.freeze({
    commentRecievedBoldText: 'Thank you!',
    commentRecievedText: '',
    commentRecievedTextSecondRow:
      'Your comment was successfully submitted and will be published in accordance with site policy. If you would like to be notified when your comment is published, please fill in your email address in the form below.',
    commentRecievedBoldTextThankYouPage: 'Thank You!',
    commentRecievedTextThankYouPage:
      'Your comment was successfully submitted and will be published in accordance with site policy.',
    commentDidNotRecieved: 'Something went wrong an your comment was not submitted to our site',
    tryAgainText: 'Try again?',
  }),
});
export const commentsSectionI18n = Object.freeze({
  signin: {
    color: 'primary',
    fontFamily: 'alt',
    signin: 'Sign in',
    text: 'to join the conversation.',
  },
  buttons: Object.freeze({
    loadMoreCommentsBtnText: 'More comments',
  }),
  selectItems: Object.freeze({
    dateDescendingItemTxt: 'Newest first',
    dateAscendingItemTxt: 'Oldest first',
    commentRatingItemTxt: 'Top-rated first',
    editorsPickItemTxt: 'Editors choice',
  }),
  texts: Object.freeze({
    chooseSortMethodText: 'Sort comment by',
  }),
  likes: Object.freeze({
    like: 'like',
    dislike: 'dislike',
  }),
});

export const commentsCountI18n = Object.freeze({
  countSuffix: 'comment to article ',
});

export const podcastI18n = Object.freeze({
  includePodcast: 'Article contains podcast',
  rssLinkText: 'Subscribe',
  allEpisodesText: 'All Episodes',
  allEpisodesUrl: 'https://www.haaretz.com/digital/podcast',
  listen: 'Listen',
});

export const commercialListI18n = Object.freeze({ text: 'שיתופי פעולה', });

export const errorPageI18n = Object.freeze({
  error404: Object.freeze({
    headline: 'PAGE NOT FOUND',
    paragraph: "We're sorry, we seem to have lost this page.",
    paragraph2: "We don't want to lose you, though!",
    button: Object.freeze({
      text: 'Back to Homepage',
      href: 'https://www.haaretz.com',
    }),
  }),
});

export const footerMobileListsI18n = Object.freeze({
  MobileList: [
    Object.freeze({
      text: 'Subscribe',
      link: 'https://promotion.haaretz.com/offers',
    }),
    Object.freeze({
      text: 'Newsletters',
      link: '/newsletter/single?segmentId=0000017e-1bbb-d2ef-a1ff-7ffb201a0000',
    }),
    Object.freeze({
      text: 'Terms',
      link: 'https://www.haaretz.com/misc/terms-and-conditions',
    }),
    Object.freeze({
      text: 'Contact Us',
      link: 'https://www.haaretz.com/contact-us',
    }),
    Object.freeze({
      text: 'My Cookies',
      type: 'button',
      className: 'optanon-toggle-display',
    }),
    Object.freeze({
      text: 'Advertise',
      link: 'https://www.haaretz.com/st/c/prod/global/mediaKit/haaretzCom.html',
    }),
    Object.freeze({
      text: 'Accessibility',
      sublinks: [
        Object.freeze({
          text: 'Report an issue',
          link: 'mailto:accessibility@haaretz.co.il',
        }),
        Object.freeze({
          text: 'Accessibility manifest',
          link: 'https://www.haaretz.com/misc/accessibility',
        }),
      ],
    }),
  ],
  ButtonName: Object.freeze({
    text: 'Download App',
    androidAppUrl: 'https://y7duh.app.goo.gl/wnG6',
    iosAppUrl: 'https://y7duh.app.goo.gl/wnG6',
  }),
  Copyright: Object.freeze({
    text: '© Haaretz Daily Newspaper Ltd. All Rights Reserved',
  }),
});

export const footerDesktopI18n = Object.freeze({
  ExpandedButton: Object.freeze({
    close: 'Close',
    showMore: 'Load more',
  }),
  Copyright: Object.freeze({
    firstRow:
      'Haaretz.com, the online English edition of Haaretz Newspaper in Israel, gives you breaking news, analyses and opinions about Israel, the Middle East and the Jewish World.',
    secondRow: '© Haaretz Daily Newspaper Ltd. All Rights Reserved',
  }),
});

export const footerSocialLinksI18n = Object.freeze({
  facebook: 'https://www.facebook.com/haaretzcom',
  twitter: 'https://twitter.com/haaretzcom',
  androidApp: 'https://play.google.com/store/apps/details?id=com.opentech.haaretz',
  appleApp: 'https://apps.apple.com/us/app/haaretz-english-edition/id504537897',
  redmail: '/newsletter/single?segmentId=0000017e-1bbb-d2ef-a1ff-7ffb201a0000',
  rss: 'https://www.haaretz.com/2022-05-29/ty-article/.premium/subscribe-to-rss-feed-and-other-ways-to-read-haaretz/00000181-0f20-d077-a1ff-ffe73cfd0000',
});

export const newsletterI18n = Object.freeze({
  input: Object.freeze({
    maxLength: EMAIL_MAX_LENGTH,
    emptyEmailError: 'Please enter a your email address',
    invalidEmailError: 'Please enter a valid email address',
    emailExistsError: 'Email already registered, Please sign-in.',
    confirmNewslettersError: 'Please confirm newsletters',
    alreadyExistError: 'The email already exists. Please try another one.',
    tooLongError: `The email address exceeds the length of ${EMAIL_MAX_LENGTH} characters`,
  }),
  buttons: Object.freeze({
    signUp: 'Sign Up',
    nonSubscribers: 'Subscribers Only',
    newsletterConfirmedButton: Object.freeze({
      success: 'Click here',
      exist: 'Click here',
      pending: '',
      error: 'Try again',
    }),
  }),
  subscribersOnly: Object.freeze({
    text: segmentName => [
      `Want to get ${segmentName || 'the'} newsletter?`,
      'This newsletter is for subscribers only',
    ],
    button: Object.freeze({
      text: 'Subscribe',
      href: 'https://promotion.haaretz.com/offers',
      variant: 'salesOpaque',
    }),
  }),
  newsletterForm: Object.freeze({
    moreNewsletterLink: 'https://bit.ly/newsletters-hdc',
    noteText: 'Please enter a valid email address',
    labelText: 'Email',
    termAndConditionText: Object.freeze({
      txt1: 'By signing up, I agree to Haaretz',
      txt2: 'terms and conditions',
      href: 'https://www.haaretz.com/misc/terms-and-conditions',
      txt3: '',
    }),
  }),
  texts: Object.freeze({
    newsletterConfirmedTitleText: Object.freeze({
      success: 'Thank you for signing up!',
      exist: 'Thank you for signing up!',
      pending: 'Almost done...',
      error: 'Excuse us, there was a error while signing up',
    }),
    newsletterConfirmedText: Object.freeze({
      success: "We've got more newsletters we think you'll find interesting",
      exist: "We've got more newsletters we think you'll find interesting",
      pending: 'To complete your registration – please click the link in the activation email we sent you',
      error: 'Please try again later',
      moreNewsletters: 'For more Newsletters',
      pendingWithGmail: ({
        txt1: 'To complete your registration – please',
        txt2: 'click the link in the activation email ',
        txt3: 'we sent you',
        href: signUpEmail => `https://mail.google.com/mail/u/${signUpEmail}/#search/from%3A(noreply@mc.haaretz.com)+in%3Aanywhere+newer_than%3A1h`,
      }),
    }),
    defaultTitleText: 'Breaking news and analyses straight to your inbox',
  }),
  serverErrors: Object.freeze({
    emailExistsError: 'The email address you have provided is already registered',
  }),
});

export const fryListI18n = Object.freeze({
  title: 'מרחבי הרשת',
});

export const welcomePageI18n = Object.freeze({
  texts: Object.freeze({
    headerHighLighted: 'ברוכים הבאים',
    headerNormal: 'לחוויה המשודרגת של הארץ',
    bullets: [
      'מהיר יותר',
      'נוח לקריאה',
      'מותאם יותר למובייל',
      'פחות פרסומות',
      'ממשק תגובות משופר',
      'קל ומהיר יותר לשתף',
    ],
  }),
  buttonText: 'לעמוד החדש',
});

export const userMenuI18n = Object.freeze({
  buttonText: 'Hi',
  logout: 'Log out',
  contactUsUrl: 'https://www.haaretz.com/contact-us',
  contactUs: 'Contact us',
  menuPos: Object.freeze({
    start: 'unset',
    end: '0',
  }),
  menuItems: Object.freeze([
    Object.freeze({
      name: 'My account',
      biActionCode: 129,
      url: 'https://www.haaretz.com/account/personal-area',
    }),
    Object.freeze({
      name: 'Newsletters',
      biActionCode: 131,
      url: '/newsletter/single?segmentId=0000017e-1bbb-d2ef-a1ff-7ffb201a0000',
    }),
    Object.freeze({
      name: 'Help',
      biActionCode: 122,
      url: 'https://www.haaretz.com/contact-us',
    }),
  ]),
  noUserData: 'LOG IN',
});

export const a11yMenuI18n = Object.freeze({
  a11yToggle: state => `${state ? 'Stop' : 'Start'} contrast`,
  animationToggle: isReduced => `${isReduced ? 'Activate' : 'Stop'} animation`,
  a11yTitle: 'accessibility',
  cancelRefresh: 'Prevent auto-refresh',
  menuItems: Object.freeze([
    Object.freeze({
      name: 'Report an accessibility issue',
      url: 'mailto:accessibility@haaretz.co.il',
    }),
    Object.freeze({
      name: 'Accessibility manifest',
      url: 'https://www.haaretz.com/misc/accessibility',
    }),
  ]),
});

export const zenTextI18n = 'Zen Read';

export const BingI18n = Object.freeze({
  timeLeftToRead: 'Remaining time to read: ',
  articlesLeftToRead: 'Remaining articles to read: ',
  readingTime: 'Reading time: ',
  articleCount: 'Remaining articles to read: ',
  homePageKicker: 'Dive deep into:',
});

export const BranniganI18n = Object.freeze({
  liveUpdates: 'LIVE UPDATES',
});
export const readingListMenuI18n = Object.freeze({
  url: 'https://www.haaretz.com/account/reading-list',
  a11yText: 'Reading List',
});

export const mastheadSubscriptionI18n = Object.freeze({
  url:
    'https://promotion.haaretz.com/offers?htm_source=site&htm_medium=button&htm_campaign=subscription&htm_campaign_type=subscription&htm_content=hello_default',
  upSellUrl:
    'https://promotion.haaretz.com/offers?htm_source=site&htm_medium=button&htm_campaign=upsell&htm_campaign_type=subscription&htm_content=hello_default',
  buyText: 'SUBSCRIBE NOW',
  a11yText: 'Subscribe now',
  a11ySubscriptionGiftText: 'Haaretz gift',
  subscriptionGiftUrl: 'https://promotion.haaretz.com/giftSubscription',
  subscriptionGiftText: 'Gift Haaretz',
  upgradeText: 'SUBSCRIBE NOW',
});

export const navbarSubscriptionI18n = Object.freeze({
  url: 'https://promotion.haaretz.com/offers?htm_source=site&htm_medium=button&htm_campaign=subscription&htm_campaign_type=subscription&htm_content=hello_default',
  name: 'Subscribe',
  a11yText: 'Subscribe now',
});

const serviceByMailConfirmationButton = Object.freeze({
  href: '/newsletter/single?segmentId=0000017e-1bbb-d2ef-a1ff-7ffb201a0000',
  text: 'Click Here',
});
export const serviceByMailI18n = Object.freeze({
  authorAlertServiceTitle: 'Send me email alerts for new articles by ',
  tagAlertServiceTitle: 'Send me email alerts for new articles about ',
  inpEmailLabelText: 'Email',
  inpEmailNoteText: 'Please enter a valid email address',
  btnSubmitText: 'Send',
  btnCancelText: 'Cancel',
  btnCloseText: 'Close',
  btnMoreNewsLettersHref: '/newsletter/single?segmentId=0000017e-1bbb-d2ef-a1ff-7ffb201a0000',
  btnMoreNewsLettersText: 'Click Here',

  inpEmailErrorRequired: 'Please enter a email address',
  inpEmailErrorInvalid: 'Please enter a valid email address',

  confirmationMessageTag: Object.freeze({
    success: tag => `You are now following the ${tag} tag by email alert.`,
    exist: tag => `You are now following the ${tag} tag by email alert.`,
    pending: tag => 'Almost done…',
  }),
  confirmationMessageAuthor: Object.freeze({
    success: name => `You are now following ${name} by email alert.`,
    exist: name => `You are now following ${name} by email alert.`,
    pending: name => 'Almost done…',
  }),
  confirmationMessageSecondRow: Object.freeze({
    success: 'For a list of Haaretz newsletters',
    exist: 'For a list of Haaretz newsletters',
    pending: 'To complete your registration – please click the link in the activation email we sent you.',
  }),
  confirmationButton: Object.freeze({
    success: serviceByMailConfirmationButton,
    exist: serviceByMailConfirmationButton,
  }),
  registrationfailureDefaultMessage: 'Registration failed, please try again later',

  campainName: 'send',
});

export const mobileAdditionalShare = Object.freeze({
  text: 'Share',
});

export const shareBarI18n = Object.freeze({
  linkToComments: 'Go to comments',
  facebookToolTip: 'Share in Facebook',
  fbMessengerToolTip: 'Share in Messenger',
  emailToolTip: 'Send in e-mail',
  printToolTip: 'Print article',
  twitterToolTip: 'Share in Twitter',
  whatsappToolTip: 'Share in WhatsApp',
  redditTooltip: 'Share in Reddit',
  googlePlusTooltip: 'Share in G+',
  telegramPlusTooltip: 'Share in Telegram',
  commentsPlusTooltip: 'Go to comments',
  mobileChangingShareButton: 'whatsapp',
  mobileFirstShareButtons: [ { name: 'twitter', }, { name: 'gift', }, ],
  giftToolTip: 'Gift this article',
  shareButtons: ({ theme, isMouseStory, }) => ({
    startButtons: [
      {
        name: 'facebookLogo',
        buttonStyles: {
          color: theme.color('facebook'),
          ':hover': {
            color: theme.color('facebook'),
          },
        },
      },
      {
        name: 'twitter',
        buttonStyles: {
          color: theme.color('twitter'),
          ':hover': {
            color: theme.color('twitter'),
          },
        },
      },
      {
        name: 'whatsapp',
        buttonStyles: {
          color: theme.color('whatsapp'),
          ':hover': {
            color: theme.color('whatsapp'),
          },
        },
      },
      {
        name: 'mail',
        buttonStyles: {
          color: theme.color('shareBar', 'button'),
          ':hover': {
            color: theme.color('shareBar', 'buttonHover'),
          },
        },
      },
      'gift',
    ],
    endButtons: [
      {
        name: 'save',
        buttonProps: { size: 4.2, },
        textMiscStyles: { color: 'inherit', },
      },
      'zen',
      'print',
    ],
    authorButtons: ({ email, facebook, twitter, }) => [
      ...(facebook ? [
        {
          name: 'facebookLogo',
          buttonStyles: {
            color: theme.color('facebook'),
            ':hover': {
              color: theme.color('facebook'),
            },
          },
        },
      ] : []),
      ...(twitter ? [
        {
          name: 'twitter',
          buttonStyles: {
            color: theme.color('twitter'),
            ':hover': {
              color: theme.color('twitter'),
            },
          },
        },
      ] : []),
      ...(email ? [
        {
          name: 'mail',
          buttonStyles: {
            color: theme.color('shareBar', 'button'),
            ':hover': {
              color: theme.color('shareBar', 'buttonHover'),
            },
          },
        },
      ] : []),
    ],
  }),
  magazineShareButtons: ({ theme, isPhotoBlog, }) => ([
    {
      name: 'twitter',
      buttonStyles: {
        color: theme.color('twitter'),
        ':hover': {
          color: theme.color('twitter'),
        },
      },
    },
    {
      name: 'whatsapp',
      buttonStyles: {
        color: theme.color('whatsapp'),
        ':hover': {
          color: theme.color('whatsapp'),
        },
      },
    },
    'gift',
    'mail',
    { name: 'comments', textMiscStyles: { display: 'none', }, },
    'print',
    {
      name: 'save',
      textMiscStyles: { color: 'inherit', },
      buttonProps: { size: 4.2, },
    },
    {
      name: 'zen',
      buttonText: theme.zenTextI18n,
      iconStyles: {
        color: theme.color('magazine', 'shareBarZen'),
      },
    },
  ]),
  faceShareButtons: () => ([]),
  timelineShareButtons: ({ theme, isBlackWhite, }) => ([
    {
      name: 'facebookLogo',
      buttonStyles: {
        color: isBlackWhite ? theme.color('bodyText', 'inverse') : theme.color('facebook'),
        ':hover': {
          color: isBlackWhite ? theme.color('quaternary') : theme.color('facebook'),
        },
      },
    },
    {
      name: 'twitter',
      buttonStyles: {
        color: isBlackWhite ? theme.color('bodyText', 'inverse') : theme.color('twitter'),
        ':hover': {
          color: isBlackWhite ? theme.color('quaternary') : theme.color('twitter'),
        },
      },
    },
    {
      name: 'reddit',
      buttonStyles: {
        color: isBlackWhite ? theme.color('bodyText', 'inverse') : theme.color('reddit'),
        ':hover': {
          color: isBlackWhite ? theme.color('quaternary') : theme.color('reddit'),
        },
      },
    },
    'mail',
    { name: 'comments', textMiscStyles: { color: 'inherit', }, },
    'print',
    {
      name: 'save',
      textMiscStyles: { color: 'inherit', },
      buttonProps: { size: 4.2, },
    },
    {
      name: 'zen',
      buttonText: theme.zenTextI18n,
      textMiscStyles: {
        color: isBlackWhite ? theme.color('bodyText', 'inverse') : 'inherit',
        ':hover': {
          color: isBlackWhite ? theme.color('quaternary') : 'inherit',
        },
      },
      iconStyles: {
        color: isBlackWhite ? theme.color('bodyText', 'inverse') : 'inherit',
        ':hover': {
          color: isBlackWhite ? theme.color('quaternary') : 'inherit',
        },
      },
    },
  ]),
  twentyQuestionsShareButtons: ({ theme, }) => ([
    {
      name: 'facebook',
      round: true,
    },
    {
      name: 'twitter',
    },
    {
      name: 'whatsapp',
    },
  ]),
  emailSubjectSuffix: '- Haaretz.com',
});

export const mobileUserMenuI18n = Object.freeze({
  noUserData: 'Log in',
  userLoggedIn: 'Log out',
  url: 'https://www.haaretz.com/mobile/login',
});

export const mobileReadingList = Object.freeze({
  url: 'https://www.haaretz.com/account/reading-list',
  text: 'Reading List',
});

export const readingListPageI18n = Object.freeze({
  title: 'Reading List',
  types: Object.freeze({
    all: 'Everything',
    recipe: 'Recipes',
    regular: 'Articles',
  }),
  empty: articleType => 'Your reading list is empty',
  howToSaveBeforeIcon: articleType => 'To save articles to your reading list, click the',
  howToSaveAfterIcon: 'icon across the website.',
  saveIconAlt: 'bookmark',
});

export const readingListActionsI18n = Object.freeze({
  a11y: Object.freeze({
    addArticle: 'Save article to reading list',
    removeArticle: 'Remove article from reading list',
    createAlert: 'Set an e-mail reminder for this article',
    removeAlert: 'Cancel the e-mail reminder for this article',
  }),
  addArticle: 'Save',
  removeArticle: 'Remove',
  // the 'saved' text is present inside the `alertQuestion` text, as they
  // should not be on separate lines on HDC.
  saved: '',

  capped: count => `The reading list is limited to ${count} articles. Saving a new article will delete the oldest saved article.`,
  cappedCheckbox: 'Do not display again',
  cancel: 'Cancel',
  ok: 'OK',

  alertQuestion: 'Article saved. Would you like an e-mail reminder?',
  alertMe: 'Remind me',
  alertCanceled: 'Email reminder canceled',
  tomorrow: 'Tomorrow',
  nextWeek: 'Next week',
  closeDialog: 'Close',
});

export const selectAriaLabel = Object.freeze({
  text: 'sort comments',
});
export const recipeInstructionsI18n = Object.freeze({
  sectionTitle: 'Preparation',
});
export const recipeTipsI18n = Object.freeze({
  sectionTitle: 'Tips',
});
export const recipeIngredientsI18n = Object.freeze({
  sectionTitle: 'Ingredients',
});

export const recipeRatingI18n = Object.freeze({
  levelText: Object.freeze({
    Easy: 'Easy',
    Medium: 'Medium',
    Hard: 'Advanced',
  }),
  highlightedText: Object.freeze({
    level: 'Difficulty',
    portions: 'Servings',
    time: 'Cooking Time',
  }),
  ratingTitle: 'User rating:',
});

export const reviewRatingI18n = Object.freeze({
  ratingTitle: 'Rating',
});

export const ratingI18n = Object.freeze({
  a11yTexts: Object.freeze({
    rateAction: rate => `Click here to rate ${rate}`,
    rating: rating => `${rating} Stars`,
  }),
});

export const liveBlogI18n = Object.freeze({
  liveUpdate: 'LIVE',
  updatesTitle: 'Updates',
  linkCopied: 'Link copied',
  loadMoreCommentsBtnText: 'Load More',
  loadMoreItemsBtnText: 'Load All Updates',
  firstToLastLabel: 'First to last',
  lastToFirstLabel: 'Last to first',
  sortBy: 'Sort By',
  keyEvents: 'Key Events',
  expand: 'Expand',
  close: 'Close',
  updatesButtonText: updatesCount => (updatesCount < 1
    ? ''
    : updatesCount === 1
      ? 'New update added'
      : `Added ${updatesCount} new updates`),
});

export const articleLayoutI18n = Object.freeze({
  commentSectionTitle: 'Comments',
});

export const breakingNewsStrip = Object.freeze({
  url: '/misc/breaking-news',
  title: 'Breaking',
  pause: 'pause',
  play: 'play',
  toPrevItem: 'previous item',
  toNextItem: 'next item',
  externalId: 'Haaretz.Element.BreakingNewsBoxElement',
});

export const dayMap = Object.freeze({
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
});

export const marketingTools = Object.freeze({
  EmailConfirmation: {
    buttonText: 'Click here',
    buttonTextAfterClick: 'Thank you',
    text1: 'Is it realy you?',
    text2: 'Validate your email to read a free article',
    text2AfterClick: 'Please validate your email address',
  },
  Weekly: {
    buttonText: 'לפרטים נוספים',
    text1: 'כל תכני הארץ בכל מכשיר בכל זמן',
    text2: 'פחות פרסומות ובאנרים',
    weeklyLink: '',
    url:
      'https://promotion.haaretz.com/offers?offer=sale-hdc',
  },
});

export const footballHeaders = Object.freeze({
  headers: [ 'קבוצה', "מש'", "נצ'", "תי'", "הפ'", 'הפרש', "נק'", ],
});

export const groupBarTabs = Object.freeze({
  headers: [ 'בית 1', 'בית 2', 'בית 3', 'בית 4', 'בית 5', 'בית 6', 'בית 7', 'בית 8', ],
});

export const nbaHeaders = Object.freeze({
  desktop: [ 'קבוצה', 'ניצחונות', 'הפסדים', 'אחוז', ],
  mobile: [ 'קבוצה', "נצ'", "הפ'", 'אחוז', ],
});

export const playoffsI18n = Object.freeze({
  top: 'פלייאוף עליון',
  bottom: 'פלייאוף תחתון',
});

export const btnOptions = Object.freeze({
  title: {
    open: 'הסתר',
    close: 'טען עוד',
  },
  degree: {
    open: 90,
    close: 270,
  },
});

export const coastHeaders = Object.freeze({
  east: 'מזרח',
  west: 'מערב',
});

export const survey = Object.freeze({
  button: 'לכל הסקרים',
});

export const surveyPie = Object.freeze({
  title: 'מפת הגושים',
  redLegend: 'ימין ודתיים',
  blueLegend: 'שמאל, מרכז וערבים',
});

export const greetingsI18n = Object.freeze({
  morning: 'Good Morning',
  noon: 'Good Day',
  afternoon: 'Good Day',
  evening: 'Good Evening',
  night: 'Good Night',
  default: 'Hi',
});

export const timeLabels = Object.freeze({
  pubDateAtText: 'Published at ',
  pubDateOnText: 'Published on ',
  modDateAtText: 'Updated: ',
  modDateOnText: 'Updated: ',
  sameDayTimeFormat: 'HH:mm',
  fullDate: "MMM d, yyyy'##' h:mm aaa zzz",
  fullDateBot: 'MMM d, yyyy h:mm aaa zzz',
  liveBlogTime: 'h:mm a',
  timeFormat: 'MMM d, yyyy',
});

export const printNonPayingUsers = Object.freeze({
  title: 'Want to enjoy a printer friendly version'.toUpperCase(),
  subTitle: 'with no ads or pictures and just the article? subscribe today'.toUpperCase(),
  button: 'Subscribe now'.toUpperCase(),
  buttonUrl: buttonText => `https://promotion.haaretz.com/offers?htm_source=site&htm_medium=pop_up&htm_campaign=printer_friendly&htm_campaign_type=subscription&htm_content=${buttonText}`,
});

export const zenNonPayingUsers = Object.freeze({
  title: 'Want to enjoy \'Zen\' reading'.toUpperCase(),
  subTitle: 'with no ads and just the article?<br/>Subscribe today'.toUpperCase(),
  button: 'Subscribe'.toUpperCase(),
  buttonUrl: buttonText => `https://promotion.haaretz.com/offers?htm_source=site&htm_medium=pop_up&htm_campaign=Zen&htm_content=${buttonText}`,
});

export const readingListDisabled = Object.freeze({
  title: 'Want to save articles',
  subTitle: 'and read on your free time?',
  button: 'Subscribe',
  href: 'https://promotion.haaretz.com/offers?htm_source=site&htm_medium=pop_up&htm_campaign=reading_list&htm_content=Subscribe',
});

export const adBlockPage = Object.freeze({
  // title: 'We\'ve noticed you\'re running ad-blocker',
  title: 'WE\'VE NOTICED YOU\'RE RUNNING AN AD-BLOCKER',
  // title1b: 'to visitors using ad blockers',
  subTitle1: 'To enjoy Haaretz\'s high-quality journalism, turn off your ad-blocker.',
  subTitle2: 'Alternately, subscribe and continue reading even with ad-blocker.',
  // text2: 'Subscribers enjoy full access to all Haaretz.com content on all platforms -
  // as well as fewer ads - for just $1 for the first month.',
  button: 'Subscribe',
  buttonUrl: 'https://promotion.haaretz.com/offers?htm_source=site&htm_medium=block-page&htm_campaign=subscripion&htm_campaign_type=purchase&htm_content=adblock-default',
  loginText: 'Already subscribed?',
  loginUrl: url => `?htm_source=site&htm_medium=link&htm_campaign=adblock-subscribe&htm_campaign_type=login&htm_content=adblock-default${url ? `&returnTo=${url}` : ''}`,
  loginUrlText: 'Log in',
  instructionsTitle: 'Follow these steps to exclude Haaretz.com from your ad-blocker:',
  instructionsList: [
    'Click the Ad Blocker extension at the top of your browser',
    'Click “Pause on this site”',
    ' to return to the blocked story and read all Haaretz.com content.',
  ],
  instructionsListImages: [
    `${assetPrefix}/static/hdc/images/adb-step-1.png`,
    `${assetPrefix}/static/hdc/images/adb-step-2.png`,
    null,
  ],
  adBlockBidenImg: Object.freeze({
    src: `${assetPrefix}/static/hdc/images/image-biden.png`,
    alt: 'IMAGE-BIDEN',
  }),
});

export const tateI18n = Object.freeze({
  loadMoreBtn: 'Load More',
});

export const infographicsI18n = Object.freeze({
  columns: Object.freeze({
    captionPrefix: 'source',
  }),
  timeline: Object.freeze({
    start: 'calc(-3rem - 8px)',
  }),
});

export const audioPlayerI18n = Object.freeze({
  ariaValueText: (currentValue, totalValue) => `${currentValue} out of ${totalValue}`,
  play: 'Play audio',
  pause: 'Pause audio',
  mute: 'Mute audio',
  unmute: 'Unmute audio',
  changeRate: (from, to) => `Change playback rate from ${from} to ${to}`,
  skip: by => `Skip ${Math.abs(by)} seconds ${by < 0 ? 'backwards' : 'ahead'}`,
  exclusiveFontWeight: 400,
  titleFontWeight: exclusive => (exclusive ? 400 : 700),
});

export const podcastChannelTitle = 'You can listen and subscribe to our podcast:';

export const podcastChannelTitleMobile = 'You can listen and subscribe to our podcast:';

export const podcastChannelNames = {
  application: 'Haaretz app',
  apple: 'Apple',
  spotify: 'Spotify',
  google: 'Google',
};

export function tldrDefaultContent(tldrWordCount) {
  const finalTldrWordCount = tldrWordCount != null && tldrWordCount !== 0 ? tldrWordCount : 100;
  return Object.freeze({
    cookie: 'Hdc',
    titleRegular: 'Would you like us to summarize articles for you?',
    titlePusr: `Article summary in ${finalTldrWordCount} words`,
    titleDefault: 'Article summary up to 100 words',
    text: 'Subscribe now to Haaretz.com to enjoy unlimited access and special services, available to subscribers only',
    purchaseButton: 'Subscribe Now',
  });
}

export const tldrPromotionLink = 'https://promotion.haaretz.com/?htm_source=site&htm_medium=button&htm_campaign=TLDR&htm_content=subscribe';

export const seoI18n = Object.freeze({
  titlePrefix: ' - Haaretz.com',
});

export const promotionsI18n = Object.freeze({
  baseUrl: 'https://promotion.haaretz.com',
});

export const paywallI18n = Object.freeze({
  or: 'or',
  sendMailConfirmationSite: 'ENG',
  sendMailConfirmationType: 'ENG_MAIL_VALIDATION',
  sendMailConfirmationSuccess: 'The email was successfully sent. Please check your inbox.',
  sendMailConfirmationFailed: 'We were unable to send you the email.',
  sendMailConfirmationTitle: 'Continue reading this article for free',
  sendMailConfirmationSubtitle: 'Check your inbox to confirm your email address',
  emailAgain: 'Resend verification email',
  sent: 'Sent',
  signUp: 'sign up',
  termsText: 'I’d like to receive emails on offers, appeals and commercial info',
  close: 'Close',
});

export const paywallMiniregI18n = Object.freeze({
  promotionaText1: 'Continue reading this article for free',
  promotionaText2: 'Register with your e-mail address',
  terms: `We will send you a daily email with articles you actually want to read.
          By signing up, you agree to Haaretz’s terms and conditions`,
  linkedTerms: {
    text: `We will send you a daily email with articles you actually want to read.
            By signing up, you agree to`,
    linkedText: 'Haaretz’s terms and conditions',
    href: 'https://www.haaretz.com/misc/terms-and-conditions',
  },
  alredyRegistered1: 'Welcome back! You are already signed up',
  alredyRegistered2: 'To continue please ',
});


export const magazineExclusivesI18n = Object.freeze({
  photoBlog: Object.freeze({}),
  familyAlbum: Object.freeze({}),
});

export const faceExclusivesI18n = Object.freeze({
  faceAffairs: Object.freeze({}),
});

export const imageGallery = Object.freeze({
  rightArrow: 'next',
  leftArrow: 'previous',
});


export const words = Object.freeze({
  and: 'and ',
});

export const algoliaI18n = Object.freeze({
  searchAuthorsPrefix: 'To all articles by ',
});

export const commentsPaywallBlocker = Object.freeze({
  subscribeUrl: 'https://promotion.haaretz.com/offers?htm_source=site&htm_campaign=talkbacks&htm_medium=button&htm_content=subs_join_faster&htm_campaign_type=purchase',
  loginUrl: 'https://login.haaretz.com/?htm_source=site&htm_campaign=talkbacks&htm_medium=button&htm_content=subs_join_faster&htm_campaign_type=purchase',
  title: 'SUBSCRIBERS JOIN THE CONVERSATION FASTER ',
  subTitle: 'Automatic approval of subscriber comments.',
  // offerText: '$1 for the first month',
  offerText: 'Subscribe today and save 40%',
  subscribe: 'SUBSCRIBE',
  askIfSignUp: 'Already signed up?',
  logIn: 'LOG IN',
});

export const labelsI18n = Object.freeze({
  promotedContent: 'Promoted Content',
  backToSection: 'Back to section',
  cooperate: 'Cooperate With',
  cooperateWith: companyName => `Paid by ${companyName}`,
  cooperateWithTooltip: companyName => `Paid by ${companyName}`,
  tooltipInfo: 'The article was produced by the commercial department of Haaretz',
  advertorialContent: 'Promoted Content',
});

export const authorPage = Object.freeze({
  bioTitle: 'Biography',
});

export const singleNewsletterI18n = Object.freeze({
  entryPageTitle: 'Newsletters',
  loadMoreButton: 'Load More',
  sendButton: 'Send',
  recommended: 'Recommended newsletters for you',
  // TODO: change this like i18n in HTZ when we have it.
  subscribersFrequencyTypes: new Map([
    [ 'daily', 'Subscribers only', ],
    [ 'twice_a_week', 'Twi-weekly', ],
    [ 'monthly', 'Monthly', ],
    [ 'weekly', 'Weekly', ],
  ]),

  frequencyTypes: new Map([
    [ 'daily', 'Daily', ],
    [ 'twice_a_week', 'Twi-weekly', ],
    [ 'monthly', 'Monthly', ],
    [ 'weekly', 'Weekly', ],
  ]),
});


export const myAlertsI18n = Object.freeze({
  loadMore: 'Load More',
  unsubscribe: 'Unsubscribe',
  subscribe: 'Subscribe',
  promotion: 'Subscribe',
  titles: Object.freeze({
    main: 'Newsletters and alerts',
    htz: 'הארץ',
    tm: 'TheMarker',
    hdc: 'My Newsletters',
    authors: 'authors',
    tags: 'tags',
    myAlerts: 'Manage My Newsletters',
    recommendations: 'Recommended newsletters for you',
  }),
  subtitle: (name, isMyAlertsPage) => [
    `Hello ${name ? ' ' : ''}${name || ''}, `,
    `${isMyAlertsPage ? ' here are all of your newsletters & alerts that you\'ve registered for.' : 'register now to get the best of Haaretz in your inbox.'} `,
  ],
  nonSubscribersSubtitle: 'Welcome to Haaretz Newsletters',
  frequency: singleNewsletterI18n.frequencyTypes,
  alertsLandingPageHref: '/newsletter/single?segmentId=0000017e-1bbb-d2ef-a1ff-7ffb201a0000',
  myAlertsLink: '/account/my-alerts',
  alertsLandingPageLinkText: 'Sign up For Newsletter ',
  empty: 'No alerts',
  textBeforeLink: 'Sign up For Newsletter ',
  nonSubscribersPromotionTitle: 'Our subscribers get more! Subscribe now to get all of Haaretz rich content',
  nonSubscribersPromotionSubtitle: 'Already signed up? ',
  linkText: ' LOG IN',
  afterLinkText: ' to manage your Newsletters & Alerts',
  promotionLink: 'https://promotion.haaretz.com/?htm_source=site&htm_medium=purchase_button&htm_campaign=newsletters_page&htm_content=subscribe',
});


export const EPaperI18n = Object.freeze({
  title: 'SUBSCRIBE NOW',
  text: 'to get full access to our E-paper',
  button: Object.freeze({
    text: 'SUBSCRIBE',
    href: 'https://promotion.haaretz.com/offers?htm_source=site&htm_medium=block-page&htm_campaign=e-paper&htm_campaign_type=subscription&htm_content=e-paper_default',
  }),
  login: Object.freeze({
    beforeLinkText: 'Already subscribed?',
    linkText: 'LOG IN',
    link: 'https://login.haaretz.com?htm_source=site&htm_medium=block-page&htm_campaign=e-paper&htm_campaign_type=subscription&htm_content=e-paper_default',
  }),
  imgData: Object.freeze({
    src: `${assetPrefix}/static/hdc/images/e-paper.png`,
    alt: 'Block page E-paper',
  }),
});

export const sliderCaptionI18n = Object.freeze({
  credit: 'credit:',
  rightSide: 'Right:',
  leftSide: 'Left:',
});

export const magazineRecipesI18n = Object.freeze({
  more: () => 'More Recipes',
});

export const personalAreaI18n = Object.freeze({
  personalInfo: Object.freeze({
    title: {
      text: 'Personal Details',
      showInPanel: true,
      onlyPaying: false,
    },
    form: Object.freeze({
      firstName: Object.freeze({
        label: 'First Name',
        error: 'Please enter first name',
        invalidError: 'Please enter a name that contains at least 2 letters and no numbers',
        requiredLong: 'Required field',
        requiredShort: '*',
        noteText: 'Please enter first name',
      }),
      lastName: Object.freeze({
        label: 'Last Name',
        noteText: 'Please enter last name',
        error: 'Please enter last name',
        invalidError: 'Please enter a name that contains at least 2 letters and no numbers',
        requiredLong: 'Required field',
        requiredShort: '*',
      }),
      phone: Object.freeze({
        label: 'Phone Number',
        requiredLong: 'Please enter phone number',
        requiredShort: '*',
        noteText: 'Please enter phone number',
        error: 'Please enter phone number',
        invalidError: 'Please enter a valid phone number',
      }),
      result: Object.freeze({
        success: 'Details updated succesfully',
        failed: 'Error while updating details',
      }),
    }),
  }),
  email: Object.freeze({
    title: {
      text: 'Update Email',
      showInPanel: false,
      onlyPaying: false,
    },
    form: Object.freeze({
      email: Object.freeze({
        label: 'Email',
        requiredLong: 'Please enter email',
        requiredShort: '*',
        noteText: 'Please enter email',
        error: 'Please enter email',
        invalidError: 'Please enter a valid email',
        buttonText: 'Continue',
        generalError: 'Error while updating email',
      }),
      result: Object.freeze({
        success: 'Email validation has been sent',
        failed: 'Failed to update email. Please try again later or contact us.',
      }),
    }),
  }),
  password: Object.freeze({
    title: {
      text: 'Password Update',
      showInPanel: false,
      onlyPaying: false,
    },
    form: Object.freeze({
      currentPassword: Object.freeze({
        label: 'Current Password',
        requiredLong: 'Required field',
        requiredShort: '*',
        noteText: 'Please enter your current password',
        error: 'Please enter your current password',
        invalidError: 'Please enter your valid current password',
      }),
      newPassword: Object.freeze({
        label: 'New Password',
        requiredLong: 'Required field',
        requiredShort: '*',
        noteText: 'Please enter new password',
        error: 'Please enter new password',
        invalidError: 'Enter a password that is at least 6 characters in length',
      }),
      newPasswordConfirm: Object.freeze({
        label: 'Confirm Password',
        requiredLong: 'Required field',
        requiredShort: '*',
        noteText: 'Please confirm new password',
        error: 'Please confirm new password',
        invalidError: 'Please confirm password',
        notEqual: 'Passwords do not match',
      }),
      result: Object.freeze({
        success: 'Password successfully changed',
        failed: 'Error while updating password',
      }),
    }),
    resetPassword: Object.freeze({
      button: {
        text: 'Forgot Password',
      },
      result: {
        success: 'Instructions to reset password have been sent to your email',
        failed: 'Error, try again',
      },
    }),
  }),
  subscription: Object.freeze({
    title: {
      text: 'My Subscription',
      showInPanel: false,
      onlyPaying: true,
    },
    form: Object.freeze({
      reason: Object.freeze({
        error: 'Please enter your phone number',
        invalidError: 'Please enter a valid phone number',
      }),
      result: Object.freeze({
        success: 'Your request to cancel your subscription has been received. You will receive an email with all the cancellation details.',
        failed: 'Error, please try again',
      }),
      errorMessagesMap: Object.freeze({
        MISSING_SUBS_NO: 'Your subscription is still being processed. Please try again in a few minutes',
      }),
    }),
    standByStage: Object.freeze({
      manageSubscruption: Object.freeze({
        text: 'Manage Subscription',
      }),
      cancellationSubscription: 'Cancel Subscription',
      subscriptions: Object.freeze({
        prefix: '',
        products: Object.freeze({
          239: 'Digital Subscription ',
        }),
        postfix: Object.freeze({
          trial: '- Trial',
          monthly: '- Monthly',
          yearly: '- Annual',
          other: '',
        }),
        apple: 'Purchased using Apple',
        android: 'Purchased using Google',
      }),
      appleHref: '/account/personal-area/apple',
      androidHref: '/account/personal-area/android',
      cancelledText: date => `Your access to Haaretz will end at: ${date}`,
      cancelledTextWithoutDate: 'Your request to cancel your subscription has been received. You will receive an email with all the cancellation details.',
    }),
    selectReasonStage: Object.freeze({
      title: 'Stop auto-renewal of this subscription',
      subTitle: 'Before you continue, can we assist you in any way?',
      text: '',
      contactOnSite: 'Contact Us',
      buttons: Object.freeze({
        continue: Object.freeze({
          text: 'Continue with Cancellation',
        }),
      }),
    }),
    confirmationStage: Object.freeze({
      title: 'Stop auto-renewal of this subscription',
      subTitle: 'Wait... Are you sure want to stop reading the top stories from Israel & the Middle East?',
      buttons: Object.freeze({
        continue: Object.freeze({
          text: 'Cancel my subscription',
        }),
        cancel: Object.freeze({
          text: 'Keep Reading',
        }),
      }),
    }),
    forceCancelButton: Object.freeze({
      text: 'Cancel immediately and refund me',
      href: 'https://haaretz.com/contact-us?issue=digitalCancel',
    }),
    apple: Object.freeze({
      title: 'Cancel subscription using Apple',
    }),
    android: Object.freeze({
      title: 'Cancel subcription using Google',
    }),
  }),
  paymentsMethod: Object.freeze({
    title: {
      text: 'Payment Method',
      showInPanel: true,
      onlyPaying: true,
    },
    text: 'Update credit card details',
  }),
  support: Object.freeze({
    title: {
      text: 'Support',
      showInPanel: false,
      onlyPaying: false,
    },
    buttons: [
      {
        text: 'Terms and conditions',
        href: 'https://www.haaretz.com/misc/terms-and-conditions',
        id: 0,
        actionCode: 69,
      },
      {
        text: 'Contact Us',
        href: 'https://www.haaretz.com/contact-us',
        id: 1,
        actionCode: 122,
      },
    ],
  }),
  form: Object.freeze({
    button: Object.freeze({
      text: 'Update Information',
    }),
  }),
  personalInvoice: Object.freeze({
    months: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', ],
    title: {
      text: 'Periodic Invoice',
      showInPanel: true,
      onlyPaying: true,
    },
    form: Object.freeze({
      buttonText: 'Send Invoice',
      periodicLabel: 'Periodic Invoice',
      error: 'Select a range up to 12 months',
      noteText: 'Please select a range up to 12 months',
      successSubmitMessage: 'Request for periodic invoice sent successfully.',
      errorSubmitMessage: 'Error please try again later',
      olderInvoiceText: 'For older invoices please',
      olderInvoiceLinkText: 'contact Customer Support',
      email: Object.freeze({
        label: 'Email',
        requiredLong: 'Please enter email',
        requiredShort: '*',
        noteText: 'Please enter email',
        error: 'Please enter email',
        invalidError: 'Please enter a valid email',
        generalError: 'Error while updating email',
      }),
    }),
    date: Object.freeze({
      fromDate: 'From:',
      untilDate: 'To:',
    }),
  }),
  cancellation: {
    thankYouForFeedback: '',
    reasons: {
      title: 'we’re sorry to see you go. We’d love to know why',
      buttonText: 'Submit',
      continue: 'Please select a reason to proceed',
      radioButtons: {
        tooExpensive: 'I don’t read enough to justify the price',
        tooExpensive1: 'I can’t afford it at the moment',
        technicalIssues: 'Technical issues',
        noTime: 'No time to read',
        nothingToRead: 'Nothing to read',
        tooManyAds: '',
        paymentIssues: 'Payment issues',
        contentIssues: 'Content complaints',
        specificStory: 'I signed up to read one story',
        other: 'None of the above reasons seem relevant',
      },
    },
    feedback: {
      title: 'Thank you for sharing! We value your feedback',
      notSpecified: 'unspecified',
      textBoxLabel: 'Feel free to add further information',
      canWeContactYou: 'Would you like us to follow up on the issue?',
      buttons: {
        continue: 'No, continue with the cancellation',
        contactMe: 'OK, get back to me',
      },
      acceptedPopup: {
        accepted: 'Thank you',
        trackingNumber: 'Inquiry number is ',
        additionalDetails: 'Your request will be answered as soon as possible',
        backToHomepage: 'Back to Homepage',
        contactUs: 'Contact Us',
      },
      errorPopup: {
        error: 'An error has occurred',
        contact: 'To proceed, please contact ',
        customerService: 'customer support',
        backToPersonalArea: 'Back to My Account',
      },
    },
    readingSuggestions: {
      title: 'Cancelling your subscription will block access to content.',
      subTitle: 'There are a few interesting articles you might have missed:',
      continueButtonText: 'Continue with the cancellation',
      cancelButtonText: 'I’ve changed my mind',
    },
    newsletterSuggestions: {
      title: 'It isn’t all or nothing',
      subTitle: 'We recommend subscribing to our newsletters to get the most interesting and important stories sent straight to you',
      continueButtonText: 'Continue with the cancellation',
      cancelButtonText: 'I’ve changed my mind',
    },
    paymentSuggestion: {
      title: 'There are other ways to pay',
      updateCreditCard: 'To update credit card details ',
      clickHere: 'Click here',
      payUsingPayPal: 'pay using PayPal',
      talkToCustomerService: 'get support from customer service',
      continueButtonText: 'Continue with the cancellation',
      cancelButtonText: 'I’ve changed my mind',
    },
    priceOffer: {
      title: 'Before you make such an important decision…',
      specialPrice: '',
      annual: 'Annual Payment',
      monthly: 'Monthly Payment',
      cancelAnytime: 'Cancel Anytime.',
      terms: 'Offer details',
      termsPopup: {
        understood: 'Accept',
      },
      changePlanRequestAcceptedPopup: {
        accepted: 'Your request to change plans was accepted',
        additionalDetails: [ 'Details about the new plan will be sent in the next 24 hours', 'A receipt will be sent 8 days after the next billing date', ],
        backToHomepage: 'Back to Homepage',
        contactUs: 'Contact Us',
      },
      changePlanRequestErrorPopup: {
        error: 'An error has occurred',
        contact: 'To proceed, please contact ',
        customerService: 'customer support',
        backToPersonalArea: 'Back to My Account',
      },
      contactMe: {
        shallWeCallYou: 'How about we get in touch with a special offer?',
        talkToMe: 'Sure, contact me',
        messageToCustomerSupport: 'Cancellation inquiry: awaiting customer representative',
        acceptedPopup: {
          accepted: 'We’ve accepted your request',
          additionalDetails: 'Customer support will get in touch ASAP',
          backToHomepage: 'Back to Homepage',
          contactUs: 'Contact Us',
        },
        errorPopup: {
          error: 'An error has occurred',
          contact: 'To proceed, please contact ',
          customerService: 'customer support',
          backToPersonalArea: 'Back to My Account',
        },
      },

    },
    editorNote: {
      title: 'Before You Go...',
      boxTitle: 'As a reader of Haaretz.com',
      boxText: 'you belong to a community that cherishes precise reporting and profound analysis on Israel, the Palestinians, the Middle East, and the Jewish world. Your subscription is vital in sustaining our mission to provide fearless coverage during this pivotal period. We hope you will stay.',
      editorInChiefName: 'Esther Solomon',
      editorInChiefJobTitle: 'Haaretz English editor-in-chief',
      mailTo: 'mailto:community@haaretz.co.il',
    },
    cancellationConfirmed: {
      title: 'Your subscription has been canceled',
      subTitle: 'Continue to enjoy full access to Haaretz until the end of your current billing period on ',
      box: {
        title: 'Make the most ',
        subTitle: 'of the last few days of your subscription',
        things: [
          { imageSrc: `${assetPrefix}/static/cancellation/newsletters.png`, imageAlt: 'Newsletters', title: 'Newsletters', subTitle: 'Sign up for our most popular newsletters', href: 'https://www.haaretz.com/newsletter/single?segmentId=0000018b-143f-df09-a9db-5dbf68b10000', },
          { imageSrc: `${assetPrefix}/static/cancellation/top-stories.png`, imageAlt: 'Top Stories', title: 'Top Stories', subTitle: 'Read today\'s top headlines', href: 'https://www.haaretz.com/ ', },
          { imageSrc: `${assetPrefix}/static/cancellation/explainers.png`, imageAlt: 'Explainers', title: 'Explainers', subTitle: 'Explore the history of key issues', href: 'https://www.haaretz.com/haaretz-explains', },
          { imageSrc: `${assetPrefix}/static/cancellation/deep-dive.png`, imageAlt: 'Deep Dive', title: 'Deep Dive', subTitle: 'Gain in-depth analysis on important topics', href: 'https://www.haaretz.com/deepdive', },
        ],
      },
      keepReading: {
        title: 'Want to keep reading?',
        subTitle: 'If you\'ve changed your mind about canceling, you can easily keep your subscription by reversing the cancellation',
      },
      buttons: {
        keepMySubscription: 'Keep my subscription',
        contactUs: 'Contact us',
        backToHomepage: 'Back to Homepage',
      },
      generalError: {
        error: 'An error has occurred',
        contact: 'To proceed, please contact ',
        customerService: 'customer support',
        backToPersonalArea: 'Back to My Account',
        newSubscriberMessage: 'Your subscription is still being processed. Please try again in a few minutes',
      },
      expireError: {
        title: 'This link has expired',
        subTitle: 'We\'d be happy to contact you with renewal options.',
        contactMe: 'Sure, contact me',
        backToHomepage: 'Back to Homepage',
      },
      contactMe: {
        messageToCustomerSupport: 'Cancellation inquiry, attempted cancellation reversal: awaiting customer representative',
        acceptedPopup: {
          accepted: 'We’ve accepted your request',
          trackingNumber: 'Inquiry number is ',
          additionalDetails: 'Customer support will get in touch ASAP',
          backToHomepage: 'Back to Homepage',
          contactUs: 'Contact Us',
        },
        errorPopup: {
          error: 'An error has occurred',
          contact: 'To proceed, please contact ',
          customerService: 'customer support',
          backToPersonalArea: 'Back to My Account',
        },
      },
      personalAreaMessage: 'We\'ve reversed your cancellation',
    },
    cancellationButtons: {
      delayedCancellation: 'Cancel subscription auto-renewal',
      immediateCancellation: 'Cancel immediately by contacting customer support',
      keepMySubscription: 'Keep my subscription',
      cancellationRequestAcceptedPopup: {
        accepted: 'We received your request to cancel your subscription',
        additionalDetails: 'Confirmation will be sent to your mailbox',
        subscriptionWillEndAt: 'Your subscription will be valid until',
        backToHomepage: 'Back to homepage',
        contactUs: 'Contact us',
        finish: 'Finish',
        termsCheckBox: 'I would like to get up to 6 premium articles a month and receive promotional emails from Haaretz',
      },
      cancellationRequestErrorPopup: {
        error: 'An error has occurred',
        contact: 'To proceed, please contact ',
        customerService: 'customer support',
        backToPersonalArea: 'Back to My Account',
        newSubscriberMessage: 'Your subscription is still being processed. Please try again in a few minutes',
      },
    },
  },
});

export const resetPasswordModalI18n = Object.freeze({
  email: Object.freeze({
    form: Object.freeze({
      email: Object.freeze({
        label: 'Email',
        requiredLong: 'Required field',
        requiredShort: '*',
        noteText: 'Please enter email',
        buttonText: 'Continue',
        generalError: 'Error please try again later',
        error: 'Please enter email',
        invalidError: 'Please enter a valid email',
      }),
      result: Object.freeze({
        success: 'A validation email has been sent',
        failed: 'Failed to update email. Please try again later or contact us.',
      }),
    }),
  }),
  password: Object.freeze({
    resetPassword: Object.freeze({
      header: 'Reset Password',
      resetPasswordButton: 'Continue',
      successMessage: 'Instructions to reset password have been sent to your email',
      successButtonText: 'Close',
      result: {
        success: 'Instructions to reset password have been sent to your email',
        failed: 'Error, try again',
      },
    }),
  }),
});

export const cancellationModalI18n = Object.freeze({
  header: 'Wait….',
  body: 'Read beyond the headlines by continuing your Haaretz subscription',
  backButtonText: 'Go Back',
  continueButtonText: 'Cancel Subscription',
});

export const authorTooltipI18n = Object.freeze({
  firstSentence: 'Would you like to get writer alerts?',
  secondSentence: 'Click the alert icon and you will be notified about new stories by ',
  close: '',
});


export const nextUpI18 = Object.freeze({
  kicker: 'Next Up',
});

export const conferenceArticleI18n = Object.freeze({
  redirectToFullArticle: 'full article',
  headerH1: (title, conferenceDate) => `${title}${conferenceDate ? ` | ${conferenceDate}` : ''}`,
});

export const giftShareI18n = Object.freeze({
  // shareKicker: '\u{1F381} You received a gift article from Haaretz!',
  shareKicker: 'You received a gift article from Haaretz',
  shareHeader: 'Gift this article',
  shareCopyToClipboard: 'Copy the link',
  shareWhatsapp: 'Share on WhatsApp',
  shareSummary: 'Send anyone a free article',
  shareError: {
    GiftQuataExceededError: [ 'You shared ', ' gift articles this month.', 'You can share ', ' more next month.', ],
    GiftServiceError: 'Gifting articles is not possible at the moment. Please try again later',
  },
  promotionText: 'Subscribe to Haaretz and gift free articles to anyone',
  promotionAction: 'Subscribe',
  introduction: [ 'You can now gift Haaretz articles to your non-subscriber friends.', ],
});

export const appPromotionTooli18n = Object.freeze({
  downloadApp: 'Haaretz app',
  useBrowser: 'Browser',
});

export const fomoToolTipI18n = Object.freeze({
  text: {
    tldr: '',
    giftArticle: '',
    comments: '',
    readingList: '',
    button: '',
  },
  href: {
    tldr: '',
    giftArticle: '',
    comments: '',
    readingList: '',
  },
});
