// @flow
import React from 'react';
import { useFela, } from 'react-fela';

import type { Node, } from 'react';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';

import weekendTeasers from './WeekendTeasers';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import ListView from '../../../ListView/NewListView';
import Section from '../../../AutoLevels/Section';
import setBiAction from '../../../../utils/setBiAction';
import RainbowListPaywallSlot from '../../../Marketing/RainbowListPaywallSlot';

type Props = ListViewProps & {
  noMargin: boolean,
};

WeekendView.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const areasTemplate = [
  {
    until: 's',
    value: `
    "he"
    "t4"
    "t5"
    "first2"
    "t3"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "he     he"
    "first2 t3"
    "first2 t3"
    "t4     t4"
    "t5     t5"
    `,
  },
  {
    from: 'l',
    value: `
    "he     he     he     he he he he he he he he he"
    "first2 first2 first2 t3 t3 t3 t4 t4 t4 t4 t4 t4"
    "first2 first2 first2 t3 t3 t3 t5 t5 t5 t5 t5 t5"
    `,
  },
];

const listMiscStyles = (theme, skewDirection, view) => ({
  position: 'relative',
  overflow: 'hidden',
  ...theme.mq({ until: 's', }, {
    ':before': {
      transform: `translateY(2rem) skewY(${1.9 * skewDirection}deg)`,
    },
  }),
  ...theme.mq({ from: 's', until: 'm', }, {
    ':before': {
      transform: `translateY(2rem) skewY(${1.6 * skewDirection}deg)`,
    },
  }),
  ...theme.mq({ from: 'm', until: 'l', }, {
    ':before': {
      transform: `translateY(1rem) skewY(${1.6 * skewDirection}deg)`,
    },
  }),
  ...theme.mq({ from: 'l', until: 'xl', }, {
    ':before': {
      transform: `skewY(${1.6 * skewDirection}deg)`,
    },
  }),
  ...theme.mq({ from: 'xl', }, {
    ':before': {
      transform: `translateY(1rem) skewY(${1.6 * skewDirection}deg)`,
    },
  }),
  ':before': {
    content: '""',
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    backgroundColor: view === 'Full_Coverage' ? 'transparent' : theme.color('Weekend', 'bg'),
    backgroundImage: view === 'Full_Coverage' ? 'linear-gradient(#dbdbdb, #2d2d2d)' : 'none',
    transformOrigin: 'logical top end',
  },
});

const first2WrapperStyle = ({ theme, }) => ({
  gridArea: 'first2',
  display: 'grid',
  textAlign: 'center',
  extend: [
    theme.mq({ until: 's', }, {
      gridGap: '2rem',
      gridTemplateColumns: '1fr 1fr',
    }),
    theme.mq({ from: 's', }, { gridGap: '4rem', }),
  ],
});

function WeekendView({
  list,
  biAction,
  gaAction,
  isLazyloadImages,
  noMargin,
}: Props): Node {
  const { theme, css, } = useFela();
  const [ isPaywallBlocked, setIsPaywallBlocked, ] = React.useState(false);
  const onRainbowToolRendered = () => setIsPaywallBlocked(true);
  const { items: teasersData, } = list;
  const skewDirection = theme.direction === 'ltr' ? -1 : 1;
  const { rainbowTargetSlot, } = list;

  const teaserRenderer = (offset = 0) => (Teaser, i) => {
    const data = teasersData[i + offset];
    return data && (
      <Teaser
        key={data.contentId}
        data={data}
        theme={theme}
        css={css}
        biAction={setBiAction(i + offset, data, biAction)}
        isLazyloadImages={isLazyloadImages}
        attrs={isPaywallBlocked ? { 'aria-hidden': true, inert: '', } : {}}
        contentId={data.contentId || data.id}
      />
    );
  };
  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[ { from: 's', until: 'l', value: '1fr 1fr', }, { from: 'l', value: 'repeat(12, 1fr)', }, ]}
      rowTemplate={[ { from: 'l', value: 'auto auto 1fr', }, ]}
      gridGap={[
        { until: 's', value: '2rem', },
        { from: 's', value: '4rem', },
      ]}
      outerBackgroundColor="transparent"
      innerBackgroundColor={noMargin ? [ 'Weekend', 'innerBg', ] : 'transparent'}
      padding={[
        { until: 's', value: '1vw 2rem 2rem', },
        { from: 's', value: '0 4rem 4rem', },
      ]}
      miscStyles={listMiscStyles(theme, skewDirection, list.view)}
      sectionMiscStyles={theme.weekendStyle.sectionMiscStyles}
      marginTop={noMargin
        ? '0'
        : [ { until: 's', value: 'calc(6rem - 1vw)', }, { from: 's', value: '8rem', }, ]
      }
      attrs={{
        'data-test': 'weekend',
      }}
    >
      <ListViewHeader
        {...list}
        biAction={biAction}
        isTopBorderDisabled
        isUrlArrowDisabled
        backgroundColor={null}
        miscStyles={{
          position: 'relative',
          paddingBottom: '0',
          marginBottom: [
            { until: 's', value: '-4px', },
            { from: 's', value: '-2rem', },
          ],
        }}
        titleMiscStyles={{
          // overwrite mobile padding baked into the header componnent
          paddingInlineStart: [ { until: 's', value: '0', }, ],
          fontWeight: '400',
          ...theme.type(5, { lines: 5, untilBp: 's', }),
          ...theme.type(7, { lines: 7, fromBp: 's', untilBp: 'xl', }),
          ...theme.type(9, { lines: 9, fromBp: 'xl', }),
        }}
      />
      { rainbowTargetSlot
        ? (
          <RainbowListPaywallSlot
            id={rainbowTargetSlot}
            gridArea={[ { until: 's', value: 't4 /t4 / t3 /t3', }, { from: 's', value: 'first2 / first2 / t5 / t5', }, ]}
            onToolRendered={onRainbowToolRendered}
          />
        )
        : null}
      <Section isFragment>
        <div className={css(first2WrapperStyle)}>
          {weekendTeasers.slice(0, 2).map(teaserRenderer(2))}
        </div>
        {weekendTeasers.slice(3, 5).map(teaserRenderer(0))}
        {weekendTeasers.slice(2, 3).map(teaserRenderer(4))}
      </Section>
    </ListView>
  );
}

export default WeekendView;
