// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { rgba, type StyleProps, } from '@haaretz/htz-css-tools';

import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import H from '../../../AutoLevels/H';
import IconAlefLogoTransparent from '../../../Icon/icons/IconAlefLogoTransparent';
import IconAvatar from '../../../Icon/icons/IconAvatar';
import Image from '../../../Image/Image';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserAuthors from '../../../TeaserAuthors/TeaserAuthors';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';
// import ActionButtons from '../../../ActionButtons/ActionButtons';

// ////////////////////////////////////////////////////////////////// //
//                               Styles                               //
// ////////////////////////////////////////////////////////////////// //

function editorialParagraphStyles({ theme, }) {
  return {
    flexGrow: '1',
    flexShrink: '0',
    marginTop: '1rem',
    overflow: 'hidden',

    '&:after': {
      backgroundImage: `linear-gradient(to top, ${theme.color(
        'white'
      )} 40%, ${theme.color('neutral', '-10', 0) || ''} 100%)`,
      bottom: '0',
      content: '""',
      height: '18rem',
      left: '0',
      position: 'absolute',
      right: '0',
    },
    extend: [
      theme.type(0, { fromBp: 's', untilBp: 'l', }),
      theme.type(-1, {
        fromBp: 'l',
        untilBp: 'xl',
        lines: 3.5,
      }),
      theme.type(-1, { fromBp: 'xl', }),
      theme.mq({ until: 's', }, { display: 'none', }),
      theme.mq({ from: 's', until: 'l', }, { height: '16rem', }),
      theme.mq({ from: 'l', }, { height: '21rem', }),
    ],
  };
}

// make sure these have the same sizes for avatar alignment

const avatarSize = [
  { until: 's', value: '15rem', },
  { from: 's', until: 'xl', value: '16rem', },
  { from: 'xl', value: '17rem', },
];

const avatarSizeIcon = [
  { until: 's', value: 15, },
  { from: 's', until: 'xl', value: 16, },
  { from: 'xl', value: 17, },
];

const stackedTeaserVerticalAlign = [ { until: 's', value: 'center', }, ];
const stackedTeaserGridGap = [
  { until: 's', value: '1rem 2rem', },
  { from: 's', value: '2rem', },
];
const nonStackedTeaserGridGap = [
  { until: 's', value: '2rem 2rem', },
  { from: 's', until: 'l', value: '3rem 2rem', },
  { from: 'l', value: '1rem 2rem', },
];

const mediaObjTeaserAreas = `
"media gap1"
"media content"
"media footer"
"media gap2"
`;
const stackedTeaserArea = '"media" "content" "footer"';
const stackedTeaserAreasTemplate = [
  { until: 's', value: mediaObjTeaserAreas, },
  { from: 's', value: stackedTeaserArea, },
];

const nonStackedTeaserAreasTemplate = `
      "media gap1"
      "media content"
      "media gap2"
    `;
const stackedTeaserColTemplate = [ { until: 's', value: 'auto 1fr', }, ];
const nonStackedTeaserColTemplate = 'auto 1fr';

const stackedTeaserRow = 'auto 1fr auto';
const mediaObjTeaserRow = '1fr auto auto 1fr';
const stackedTeaserRowTemplate = [
  { until: 's', value: mediaObjTeaserRow, },
  { from: 's', value: stackedTeaserRow, },
];
const nonStackedTeaserRowTemplate = '0 1fr 0';
const areasTemplate = [
  {
    until: 's',
    value: `
    "he"
    "comic"
    "editorial"
    "op-ed1"
    "op-ed2"
    "op-ed3"
    "quote"
    `,
  },
  {
    from: 's',
    until: 'l',
    value: `
    "he        he        he"
    "editorial editorial editorial"
    "op-ed1    op-ed2    op-ed3"
    "op-ed4    op-ed4    quote"
    "op-ed5    op-ed5    quote"
    `,
  },
  {
    from: 'l',
    value: `
    "he editorial op-ed1 op-ed2 op-ed3"
    "he op-ed4    op-ed5 op-ed5 quote"
    `,
  },
];

export function getItemRenderer(
  biAction: ?ListBiActionType,
  isLazyloadImages: boolean,
  teasers: Array<React.StatelessFunctionalComponent<any>>,
  teaserGridAreaNames: Array<string>,
  theme: Object
) {
  return function itemRenderer(data: Object, i: number) {
    if (data && teasers[i]) {
      return teasers[i]({
        data,
        /**
         * Is the teaser at position `i` stacked on all
         * breakpoints except the default one (mobile)
         */
        isStackedAboveMobile: (i && i <= 4) || i === 7,
        gridArea: teaserGridAreaNames[i],
        biAction: biAction ? setBiAction(i, data, biAction) : null,
        isLazyloadImages,
        theme,
      });
    }
    return null;
  };
}

// ////////////////////////////////////////////////////////////////// //
//                             Container                              //
// ////////////////////////////////////////////////////////////////// //

export const teasers = [
  Comic,
  Editorial,
  OpEdTeaser,
  OpEdTeaser,
  OpEdTeaser,
  OpEdTeaser,
  OpEdTeaser,
  QuoteTeaser,
];

export const teaserGridAreaNames = [
  'comic',
  'editorial',
  'op-ed1',
  'op-ed2',
  'op-ed3',
  'op-ed4',
  'op-ed5',
  'quote',
];

Hubert.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};
export default function Hubert({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: ListViewProps): React.Node {
  const { theme, } = useFela();
  const { items, extraLinks, ...restOfList } = list || {};
  if (!(items && items.length)) return null;
  const itemRenderer = getItemRenderer(
    biAction,
    isLazyloadImages,
    teasers,
    teaserGridAreaNames,
    theme
  );

  return (
    <ListView
      areasTemplate={areasTemplate}
      colTemplate={[
        { from: 's', until: 'l', value: '1fr 1fr 1fr', },
        { from: 'l', value: '1fr 2fr 1fr 1fr 1fr', },
      ]}
      attrs={{
        'data-test': 'hubert',
      }}
    >
      {restOfList.title || restOfList.commercialLinks?.length || extraLinks?.length ? (
        <ListViewHeader
          {...restOfList}
          extraLinks={extraLinks?.length ? extraLinks.slice(0, 5) : null}
          biAction={biAction}
          isSticky
        />
      ) : null}
      <Section isFragment>{items.map(itemRenderer)}</Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                              TEASERS                               //
// /////////////////////////////////////////////////////////////////////

type TeaserPropTypes = {
  biAction: ?() => void,
  data: TeaserDataType,
  gridArea?: ?string,
  isLazyloadImages: boolean,
  theme: Object,
  miscStyles?: StyleProps,
};

Comic.defaultProps = {
  isLazyloadImages: true,
  miscStyles: null,
  gridArea: null,
};

export function Comic({
  biAction,
  data,
  gridArea,
  isLazyloadImages,
  miscStyles,
  theme,
}: TeaserPropTypes): React.Node {
  const titleClasses = useFela().css({
    backgroundColor: theme.color('secondary', 'base'),
    color: 'white',
    fontWeight: '400',
    // TODO: make sure that we dont need the padding top any more
    // padding: '1rem',
    justifySelf: 'start',
    extend: [ theme.type(-1), ],
  });

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={'"media" "content"'}
      miscStyles={
        miscStyles || {
          display: [ { from: 's', value: 'none', }, ],
        }
      }
    >
      <TeaserMedia
        data={data}
        onClick={biAction}
        miscStyles={{
          padding: '1rem 1rem 0',
        }}
      >
        <Image
          image={image}
          lazyLoad={isLazyloadImages}
          miscStyles={{ width: '100%', }}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'headline',
            sizes: [ { size: 'calc(100vw - 36px)', }, ],
            widths: [ 548, 380, 340, 285, ],
          })}
        />
      </TeaserMedia>
      <H className={titleClasses}>
        <TeaserResponsiveText text={data.title} mobileText={data.title} kind="title" />
      </H>
    </Teaser>
  );
}

Editorial.defaultProps = {
  isLazyloadImages: true,
  gridArea: null,
  miscStyles: null,
};
export function Editorial({
  biAction,
  data,
  gridArea,
  isLazyloadImages,
  theme,
}: TeaserPropTypes): React.Node {
  const { css, } = useFela();
  const paragraphClasses = css(editorialParagraphStyles);
  return (
    <Teaser
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={[
        { until: 's', value: mediaObjTeaserAreas, },
        { from: 's', value: '"content" "footer"', },
      ]}
      colTemplate={stackedTeaserColTemplate}
      rowTemplate={[
        { until: 's', value: mediaObjTeaserRow, },
        { from: 's', value: '1fr auto', },
      ]}
      gridGap={stackedTeaserGridGap}
      miscStyles={{
        padding: [
          { until: 's', value: '2rem', },
          { from: 's', value: '2rem 2rem', },
        ],
        alignItems: stackedTeaserVerticalAlign,
      }}
    >
      <TeaserMedia
        data={data}
        onClick={biAction}
        miscStyles={{
          backgroundColor: theme.color('primary', '-5'),
          borderRadius: '50%',
          display: [ { from: 's', value: 'none', }, ],
          padding: '2.5rem',
        }}
      >
        <IconAlefLogoTransparent size={10} color="primary" />
      </TeaserMedia>
      <TeaserContent>
        {data.credit ? (
          <TeaserAuthors
            authors={Array.isArray(data.credit) && data.credit.length > 1 ? [ data.credit[0], ] : data.credit}
            miscStyles={{
              fontWeight: 'bold',
              color: theme.color('secondary'),
              fontFamily: theme.fontStacks[theme.framedFont],
              display: [ { until: 'l', value: 'block', }, ],
              paddingBottom: [ { until: 'l', value: '1rem', }, ],
              type: [
                { until: 's', value: -2, },
                { from: 's', until: 'l', value: -1, },
                { from: 'xl', value: -1, },
              ],
            }}
          />
        ) : null}
        <TeaserHeader
          {...data}
          typeScale={[
            { until: 's', value: -1, },
            { from: 's', value: 1, },
          ]}
          showKicker={false}
          miscStyles={{
            marginTop: [ { from: 's', value: '1rem', }, ],
          }}
          onClick={biAction}
        />
        <p className={paragraphClasses}>{data.firstParagraph}</p>
      </TeaserContent>
      <TeaserFooter data={data} miscStyles={{ position: 'relative', }} />
    </Teaser>
  );
}

QuoteTeaser.defaultProps = {
  isLazyloadImages: true,
  gridArea: null,
  miscStyles: null,
};
export function QuoteTeaser({
  biAction,
  data,
  gridArea,
  isLazyloadImages,
  theme,
  miscStyles,
}: TeaserPropTypes): React.Node {
  return (
    <Teaser
      backgroundColor={[
        { until: 's', value: [ 'quoteTeaser', 'bgMobile', ], },
        { from: 's', value: [ 'quoteTeaser', 'bg', ], },
      ]}
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={stackedTeaserAreasTemplate}
      colTemplate={stackedTeaserColTemplate}
      rowTemplate={stackedTeaserRowTemplate}
      gridGap="1rem 2rem"
      miscStyles={{
        padding: '2rem',
        alignItems: stackedTeaserVerticalAlign,
        ...(miscStyles || {}),
      }}
    >
      <TeaserMedia data={data} disableAnchor />
      <TeaserHeader
        {...data}
        typeScale={[
          { until: 's', value: -1, },
          { from: 's', until: 'xl', value: -1, },
          { from: 'xl', value: -2, },
        ]}
        showKicker={false}
        onClick={biAction}
        miscStyles={{
          color: [
            { until: 's', value: theme.color('quoteTeaser', 'textMobile'), },
            { from: 's', value: theme.color('quoteTeaser', 'text'), },
          ],
        }}
        isGridItem
      />
      <TeaserFooter
        data={data}
        miscStyles={{
          color: theme.color('secondary', '-2'),
          fontFamily: theme.fontStacks[theme.framedFont],
          fontWeight: 'bold',
          type: [
            { until: 's', value: -2, },
            { from: 's', until: 'xl', value: -1, },
            { from: 'xl', value: -2, },
          ],
        }}
        showAuthor
      />

    </Teaser>
  );
}

type OpEdProps = TeaserPropTypes & {
  isStackedAboveMobile: boolean,
};
OpEdTeaser.defaultProps = {
  isLazyloadImages: true,
  gridArea: null,
  miscStyles: null,
};
export function OpEdTeaser({
  biAction,
  data,
  gridArea,
  isLazyloadImages,
  isStackedAboveMobile,
  theme,
  miscStyles,
}: OpEdProps): React.Node {
  const areas = isStackedAboveMobile
    ? stackedTeaserAreasTemplate
    : nonStackedTeaserAreasTemplate;
  const colTemplate = isStackedAboveMobile
    ? stackedTeaserColTemplate
    : nonStackedTeaserColTemplate;
  const rowTemplate = isStackedAboveMobile
    ? stackedTeaserRowTemplate
    : nonStackedTeaserRowTemplate;
  const horizontalAlign = isStackedAboveMobile
    ? [ { from: 's', value: 'center', }, ]
    : undefined;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId}
      gridArea={gridArea}
      areasTemplate={areas}
      colTemplate={colTemplate}
      rowTemplate={rowTemplate}
      gridGap={
        isStackedAboveMobile ? stackedTeaserGridGap : nonStackedTeaserGridGap
      }
      miscStyles={{
        ...(gridArea === 'op-ed4' || gridArea === 'op-ed5'
          ? { display: [ { until: 's', value: 'none', }, ], marginTop: '', }
          : {}),
        padding: [
          { until: 's', value: '2rem', },
          {
            from: 's',
            value: isStackedAboveMobile ? '2rem 2rem 1rem' : '2rem 2rem 2rem',
          },
        ],
        alignItems: isStackedAboveMobile
          ? stackedTeaserVerticalAlign
          : 'center',
        textAlign: horizontalAlign,
        justifyItems: horizontalAlign,
        ...(miscStyles || {}),
      }}
    >
      <TeaserMedia
        data={data}
        onClick={biAction}
        miscStyles={{
          backgroundColor: theme.color('primary', '-5'),
          borderRadius: '50%',
          height: avatarSize,
          overflow: 'hidden',
          paddingTop: '1rem',
          width: avatarSize,
        }}
      >
        {image?.type === 'image' ? (
          <Image
            image={image}
            imgOptions={getImageAssets({
              bps: theme.bps,
              aspect: 'square',
              sizes: [ { size: '119px', }, ],
              widths: [ 180, 119, ],
            })}
            lazyLoad={isLazyloadImages}
            miscStyles={{ backgroundColor: theme.color('primary', '-5'), }}
          />
        ) : (
          <IconAvatar
            size={avatarSizeIcon}
            color={[ 'primary', '-3', ]}
            miscStyles={{
              transform: 'translateY(.1em)',
            }}
          />
        )}
      </TeaserMedia>
      <TeaserContent>
        {data.credit ? (
          <TeaserAuthors
            authors={Array.isArray(data.credit) && data.credit.length > 1 ? [ data.credit[0], ] : data.credit}
            miscStyles={{
              color: theme.color('secondary'),
              display: 'inline-block',

              fontFamily: theme.fontStacks[theme.framedFont],
              fontWeight: 'bold',
              paddingBottom: [ { until: 's', value: '1rem', }, ],
              type: [
                { until: 's', value: -2, },
                { from: 's', until: 'xl', value: -1, },
                { from: 'xl', value: -2, },
              ],
            }}
          />
        ) : null}
        <TeaserHeader
          showKicker={false}
          {...data}
          typeScale={[
            { until: 's', value: -1, },
            { from: 's', until: 'xl', value: -1, },
            { from: 'xl', value: -2, },
          ]}
          onClick={biAction}
        />
      </TeaserContent>
    </Teaser>
  );
}
