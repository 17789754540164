// @flow
import * as React from 'react';

import Picture from '../../../Image/Picture';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getPictureAssets from '../../../../utils/getPictureAssets';

import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type ZappItemProps = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  hideImageOnMobile: boolean,
  biAction: ?() => void,
  biImpression: () => void,
  theme: Object,
  isThemarker?: boolean,
};

ZappItem.defaultProps = {
  isLazyloadImages: true,
  hideImageOnMobile: false,
  isThemarker: false,
};

const areasMediaObjTeaser = `
"media content"
"media footer"
`;

const areasStackedTeaser = '"media" "content" "footer"';
export default function ZappItem({
  data,
  isLazyloadImages,
  hideImageOnMobile,
  biAction,
  biImpression,
  theme,
  isThemarker,
}: ZappItemProps): React.Node {
  const { teaser, } = theme.zappStyle;

  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      key={data.contentId || data.id}
      data={data}
      onClick={biAction}
      areasTemplate={
        hideImageOnMobile
          ? [
            { until: 's', value: areasStackedTeaser, },
            { from: 's', until: 'l', value: areasMediaObjTeaser, },
            { from: 'l', value: areasStackedTeaser, },
          ]
          : [ { until: 'l', value: areasMediaObjTeaser, }, { from: 'l', value: areasStackedTeaser, }, ]
      }
      colTemplate={
        hideImageOnMobile
          ? [ { from: 's', until: 'l', value: '1fr 1fr 0', }, ]
          : [ { until: 's', value: '18rem 1fr 0', }, { from: 's', until: 'l', value: '1fr 1fr 0', }, ]
      }
      rowTemplate={[
        {
          until: 's',
          value: hideImageOnMobile ? '0 auto auto' : '1fr auto',
        },
        { from: 's', until: 'l', value: 'auto 1fr', },
        { from: 'l', value: 'auto 1fr auto', },
      ]}
      gridGap={teaser.gridGap}
      miscStyles={{
        ...(data.highlight ? { backgroundColor: theme.color('neutral', '-2'), paddingBlockEnd: [ { from: 's', value: '0.5rem', }, ], } : {}),
      }}
      contentId={data.contentId || data.id}
    >
      <TeaserMedia
        data={data}
        miscStyles={hideImageOnMobile ? { display: [ { until: 's', value: 'none', }, ], } : null}
        onClick={biAction}
      >
        {image ? (
          <Picture
            lazyLoad={isLazyloadImages}
            {...getPictureAssets({
              bps: theme.bps,
              imgData: image,
              defaultImgOptions: {
                sizes: '108px',
                aspect: 'square',
                widths: [ 108, 216, ],
              },
              sources: [
                {
                  from: 's',
                  aspect: 'headline',
                  sizes: [
                    { from: 'xl', size: isThemarker ? '385px' : '280px', },
                    { from: 'l', size: isThemarker ? '310px' : '226px', },
                    { from: 'm', size: '360px', },
                    { from: 's', size: '276px', },
                  ],
                  widths: [ 226, 276, 280, 310, 390, 552, 720, ],
                },
              ],
            })}
          />
        ) : null}
      </TeaserMedia>
      <TeaserHeader
        {...data}
        typeScale={teaser.headerTypeScale}
        onClick={biAction}
        isGridItem
        wrapperMiscStyles={{
          ...hideImageOnMobile
            ? { padding: [ { until: 's', value: '0 1rem', }, ], }
            : {
              paddingTop: [ { until: 's', value: '1rem', }, ],
              paddingInlineEnd: [ { until: 's', value: '1rem', }, ],
            },
          ...(data.highlight ? {
            color: 'white',
            margin: [
              { until: 's', value: '0.5rem 0.5rem 0 1.5rem', },
              { from: 's', until: 'l', value: '1.5rem 1rem 1rem 1rem', },
              { from: 'l', value: '0.5rem 0.5rem 0 1.5rem', },
            ], } : {}),
        }}
        kickerMiscStyles={data.highlight ? { color: theme.color('negative', '-2'), } : null}
      />
      <TeaserFooter
        swapReportingFromAndTime
        data={data}
        showAuthor
        showLive={!!data.liveUpdates}
        showRank={teaser.footer.showRank}
        showMediaFlags
        isHighlight={data.highlight}
        showTime={teaser.footer.showTime}
        showCommentsCount={teaser.footer.showCommentsCount}
        miscStyles={{
          display: 'flex',
          alignItems: 'center',
          ...(data.highlight ? { color: theme.color('secondary', '-2'), marginInlineEnd: '0.5rem', marginInlineStart: '1.5rem', } : {}),
          ...hideImageOnMobile
            ? { padding: [ { until: 's', value: '0 1rem 1rem', }, ], }
            : {
              paddingBottom: [ { until: 's', value: '1rem', }, ],
              paddingInlineEnd: [ { until: 's', value: '1rem', }, ],
            },
        }}
      />
    </Teaser>
  );
}
