import breakUrl from './breakUrl';

export default function switchToDomain(fqdnToReplace, service) {
  const fqdnToReplaceNormalized = fqdnToReplace.replace(/\.k8s-(prod|stage)/, '');
  const serviceToReplaceNormalized = service.replace(/\.k8s-(prod|stage)/, '');

  const k8sPostfix = /\.(k8s-(?:prod|stage))/.exec(fqdnToReplace)?.[1];

  const { domain, } = breakUrl(serviceToReplaceNormalized);
  let { domain: newDomain, } = breakUrl(fqdnToReplaceNormalized);

  if (k8sPostfix && !service.includes(k8sPostfix)) {
    newDomain = `${k8sPostfix}.${newDomain}`;
  }

  const result = service.replace(domain, newDomain);

  return result;
}
