// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { useRouter, } from 'next/router';
import Teaser from '../../../Teaser/NewTeaser';
import HtzLink from '../../../HtzLink/HtzLink';
import H from '../../../AutoLevels/H';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
import TeaserReadingTime from '../../../TeaserReadingTime/TeaserReadingTime';
import TeaserReadingArticleCount from '../../../TeaserReadingArticleCount/TeaserReadingArticleCount';

import Highlight from '../../../Highlight/Highlight';

import Picture from '../../../Image/Picture';

import getPictureAssets from '../../../../utils/getPictureAssets';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import checkSiteFromConfig from '../../../../utils/checkSiteFromConfig';

const { isHDC, } = checkSiteFromConfig();

type PropsType = {
    item: TeaserDataType,
    biAction: ?() => void,
};

const gridTemplate = '"media" "content" ';

const teaserContentLayoutStyles = {
  display: 'flex',
  bottom: '0.2rem',
  start: 0,
  zIndex: 1,
  position: 'absolute',
  maxWidth: '75%',
  flexDirection: 'column',
};

function headlineStyle({ theme, }) {
  return {
    fontWeight: 400,
    color: theme.color('ogiList', 'headlineColor'),
    extend: [
      theme.type(2, { untilBp: 'l', lines: '4.5', }),
      theme.type(4, { fromBp: 's', untilBp: 'xl', lines: '6', }),
      theme.type(5, { fromBp: 'xl', lines: '6.25', }),
    ],
  };
}

export default function AkaTeaser({ item, biAction, }: PropsType) {
  const router = useRouter();
  const path = router.query.path || '';
  const isHomePage = path === '/';
  const { theme, css, } = useFela({ isHDC, isHomePage, });
  const { readingTime, homePageKicker, } = theme.BingI18n || {};


  if (!item) return null;

  const image = item?.mobileImage || item?.image;

  return (
    <Teaser areasTemplate={gridTemplate} miscStyles={{ position: 'relative', }}>
      <TeaserMedia data={item}>
        <Picture
          {...getPictureAssets({
            bps: theme.bps,
            imgData: image,
            defaultImgOptions: {
              aspect: 'square',
              sizes: [ { until: 's', size: 'calc(100vw - 24px)', }, ],
              widths: [ 575, ],
            },
            sources: [
              {
                aspect: 'landscape',
                from: 's',
                sizes: [
                  { from: 'xl', size: '1280px', },
                  { from: 'l', size: '973px', },
                  { from: 's', size: '552px', },
                ],
                widths: [ 1280, 973, 552, ],
              },
            ],
          })}
        />
      </TeaserMedia>
      <div className={css(teaserContentLayoutStyles)}>
        <Highlight
          highlightColor={[ 'ogiList', 'readingtimeHighlight', ]}
          miscStyles={{
            color: theme.color('ogiList', 'readingtimeColor'),
          }}
        >
          {isHDC ? (
            <TeaserReadingArticleCount
              articleCount={isHomePage ? '' : item.articleCount}
              {...isHomePage ? { text: homePageKicker, } : {}}
              miscStyles={{
                type: 0,
                paddingInlineStart: '1.5rem',
                paddingInlineEnd: '1rem',
              }}
            />
          ) : (
            <TeaserReadingTime
              articleWordCount={item.wordCount}
              text={readingTime}
              iconSize={2.5}
              miscStyles={{
                type: 0,
                padding: '1rem',
              }}
            />
          )}
        </Highlight>
        <HtzLink
          href={item.path}
          onClick={biAction}
          target={item.linkTarget}
        >
          <H className={css(headlineStyle)}>
            <div className={css(akaTextStyle)}>
              <TeaserResponsiveText
                text={item.title}
                mobileText={item.titleMobile}
                kind="title"
              />
            </div>
          </H>
        </HtzLink>
      </div>
    </Teaser>
  );
}

function akaTextStyle({ theme, isHomePage, }) {
  return {
    display: 'inline',
    boxDecorationBreak: 'clone',
    backgroundColor: theme.color(
      'ogiList',
      isHDC && isHomePage ? 'readingtimeHighlight' : 'headlineBgColor'
    ),
    paddingInlineStart: '2rem',
    paddingInlineEnd: '1rem',
    extend: [
      theme.mq({ until: 's', }, { paddingBlockEnd: '0.3rem', }),
      theme.mq({ from: 's', until: 'xl', }, { paddingBlockEnd: '0.2rem', }),
      theme.mq({ from: 'xl', }, { paddingBlockEnd: '0.1rem', }),
    ],
  };
}
