// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import type { GalleryDataType, } from '../../../../flowTypes/GalleryDataType';
import type { HTMLEmbedDataType, } from '../../../../flowTypes/HTMLEmbedDataType';
import type { ImageDataType, } from '../../../../flowTypes/ImageDataType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';
import type { CountdownType, } from '../../../../flowTypes/CountdownType';

import { isImage, isEmbed, isGallery, } from '../../../../utils/validateType.js';
import Image from '../../../Image/Image';
import RelatedArticleLinks from '../Wong/RelatedArticleLinks';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserFooter from '../../../TeaserFooter/TeaserFooter';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserSubtitle from '../../../TeaserSubtitle/TeaserSubtitle';
import useGetMediaComponent from '../../../../hooks/useGetMediaComponent';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';

import useGetComponent from '../../../../hooks/GetComponentContext/useGetComponent';
import useOneTime from '../../../../hooks/useOneTime';

import H from '../../../AutoLevels/H';
import TeaserResponsiveText from '../../../TeaserResponsiveText/TeaserResponsiveText';
import Highlight from '../../../Highlight/Highlight';
import AboveBlockLink from '../../../BlockLink/AboveBlockLink';
import HtzLink from '../../../HtzLink/HtzLink';

type Props = {
  list: ListDataType,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  isLazyloadImages: boolean,
  countdownObj: ?CountdownType,
};

const areasTeaser = `
"media media"
"content ."
"footer  ."
"links   ."
`;

const areasStackedTeaser = `
"media media media"
"...   content ..."
"...   footer  ..."
"...   links   ..."
`;

Amy.defaultProps = {
  isLazyloadImages: false,
  biAction: null,
  biImpression: null,
  countdownObj: null,
};
export default function Amy({
  isLazyloadImages,
  list: {
    items: [ item, ],
  },
  biAction: rawBiAction,
  biImpression,
  countdownObj,
}: Props): React.Node {
  const relatedPadding = '2rem';
  const { theme, css, } = useFela();
  const getComponent = useGetComponent();
  const ImageGalleryTeaser = getComponent('imageGalleryTeaser');
  const getMediaComponent = useGetMediaComponent(Image, ImageGalleryTeaser);
  const image = item?.mobileImage || item?.image;
  const media = item.media || image || null;
  const MediaComponent = getMediaComponent(media && media.kind);
  const mediaProps = getMediaProps(media, theme);
  const biAction = setBiAction(0, item, rawBiAction);
  useOneTime(!!biImpression && typeof biImpression === 'function' && !!item, setBiImpression(0, item, biImpression));

  const areasTemplate = [
    { until: 's', value: areasStackedTeaser, },
    { from: 's', until: 'l', value: areasStackedTeaser, },
    { from: 'l', value: areasTeaser, },
  ];
  const rowTemplate = [
    { from: 'l', until: 'xl', value: 'auto  auto auto', },
    { from: 'xl', value: 'auto auto auto 1fr ', },
  ];
  return (
    <Teaser
      areasTemplate={areasTemplate}
      rowTemplate={rowTemplate}
      colGap={[
        { until: 's', value: '2rem', },
        { from: 'l', until: 'xl', value: '4rem', },
        { from: 'xl', value: '2rem', },
      ]}
      rowGap="1rem"
      gridGap={null}
      miscStyles={{
        gridColumnEnd: [ { from: 'l', until: 'xl', value: 'span 2', }, ],
        gridTemplateColumns: [ { until: 's', value: '0 auto 0', }, ],
        margin: [ { until: 's', value: '0 -2rem', }, ],
        width: 'auto',
      }}
      attrs={{
        'data-test': 'amy',
      }}
      contentId={item.contentId || item.id}
    >
      <TeaserMedia data={item} onClick={biAction}>
        {mediaProps ? (
          <MediaComponent {...mediaProps} lazyLoad={isLazyloadImages} />
        ) : null}
        <AboveBlockLink>
          {({ className, }) => (
            <div className={css(textWrapperStyle)}>
              {item.title
                && (item.exclusive || item.exclusiveMobile) && (
                  // We use an offset here, because the title should be the same level
                  // as a header inside a section, no the same as a section's title
                  <H className={css(headlineStyle)}>
                    <HtzLink href={item.path} className={className}>
                      <Highlight
                        displayBefore={false}
                        isBlock
                        highlightColor={[ 'amy', 'innerTextBg', ]}
                        miscStyles={{
                          color: theme.color('amy', 'innerText'),
                          fontFamily: theme.fontStacks[theme.framedFont],
                          opacity: '0.92',
                          ...item.exclusiveMobile ? {} : { display: [ { until: 's', value: 'none', }, ], },
                          ...item.exclusive ? {} : { display: [ { from: 's', value: 'none', }, ], },
                        }}
                      >
                        <span
                          className={css({
                            paddingInlineStart: '0.8rem',
                            paddingInlineEnd: '0.8rem',
                          })}
                        >
                          <TeaserResponsiveText
                            text={item.exclusive}
                            mobileText={item.exclusiveMobile}
                            kind="kicker"
                          />
                        </span>
                      </Highlight>
                    </HtzLink>
                  </H>
              )}
            </div>
          )}
        </AboveBlockLink>
      </TeaserMedia>
      <TeaserContent
        miscStyles={{ marginBlockStart: [ { from: 's', value: '1rem', }, ], }}
      >
        <TeaserHeader
          kickerIsBlock
          isH1
          {...item}
          typeScale={[
            { until: 's', value: 2, },
            { from: 's', until: 'xl', value: 5, },
            { from: 'xl', value: 4, },
          ]}
          showKicker={false}
          onClick={biAction}
          {...(countdownObj
            ? {
              showKicker: false,
              countdownObj,
            }
            : {})}
        />
        <TeaserSubtitle
          {...item}
          typeScale={[
            { from: 's', until: 'l', value: 0, },
            { until: 'xl', value: 0, },
            { from: 'xl', value: -1, },
          ]}
          miscStyles={{
            display: [ { until: 's', value: 'none', }, ],
            marginTop: [ { from: 's', value: '1rem', }, ],
            fontWeight: 400,
          }}
        />
      </TeaserContent>
      <TeaserFooter
        swapReportingFromAndTime
        showAuthor
        showLive={!!item.liveUpdates}
        data={item}
        miscStyles={{
          display: 'flex',
          alignItems: [ { until: 'xl', value: 'flex-end', }, ],
        }}
        typeScale={[
          { until: 's', value: -3, },
          { from: 's', until: 'xl', value: -2, },
          { from: 'xl', value: -3, },
        ]}
      />
      {item.relatedArticles && (
        <RelatedArticleLinks
          relatedArticles={item.relatedArticles}
          biAction={rawBiAction}
          relatedPadding={relatedPadding}
        />
      )}
    </Teaser>
  );
}

// /////////////////////////////////////////////////////////////////////
//                               UTILS                                //
// /////////////////////////////////////////////////////////////////////

function getImgOptions(theme) {
  return {
    bps: theme.bps,
    aspect: 'headline',
    sizes: [
      { from: 'xl', size: '604px', },
      { from: 'l', size: '556px', },
      { from: 'm', size: '720px', },
      { from: 's', size: '552px', },
      { size: '100wv', },
    ],
    widths: [ 375, 469, 556, 604, 720, ],
  };
}

function getImageProps(media: ImageDataType, theme: Object): Object {
  return {
    data: media,
    imgOptions: getImageAssets(getImgOptions(theme)),
  };
}

function getEmbedProps(media: HTMLEmbedDataType): Object {
  return media.inputTemplate === 'Youtube'
    ? {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: {
        ...media.settings,
        controls: '0',
        autoplay: true,
        loop: '1',
        logo: '1',
        startAt: 0,
        related: '0',
        mute: true,
      },
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    }
    : {
      ...media,
      source: media.source,
      embedType: media.embedType,
      settings: media.settings,
      showCaption: false,
      inputTemplate: media.inputTemplate,
      caption: media.caption,
      credit: media.credit,
    };
}

export function getMediaProps(
  media: ?(ImageDataType | HTMLEmbedDataType | GalleryDataType),
  theme: Object
): ?Object {
  if (media) {
    if (isImage(media)) return getImageProps(media, theme);
    if (isEmbed(media)) return getEmbedProps(media);
    if (isGallery(media)) {
      return {
        ...media,
        showTitle: false,
        imgOptions: getImgOptions,
      };
    }
  }
  return null;
}

// /////////////////////////////////////////////////////////////////////
//                               STYLE                                //
// /////////////////////////////////////////////////////////////////////

function textWrapperStyle({ theme, }) {
  return {
    position: 'absolute',
    start: 0,
    bottom: 0,
    maxWidth: '90%',
  };
}

function headlineStyle({ theme, }) {
  return {
    fontWeight: '700',
    extend: [
      theme.type(-1, { untilBp: 's', lines: 3.9, }),
      theme.type(0, { fromBp: 's', lines: 4.3, }),
    ],
  };
}
